import {
  gql,
} from 'apollo-boost';

export default gql `
mutation($data: JobTypeBreakdownInput!) {
  upsertJobTypeBreakdown(data: $data) {
    description
    job_type {
      id
      description
    }
    net
    gross
  }
}
`;