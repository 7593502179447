import React from 'react';

import AuthRoute from '../hoc/AuthRoute';
import Jobs from '../jobs/Jobs';
import Job from '../jobs/Job';
import Navigation from './Navigation';
import ComingSoon from '../global/ComingSoon';
import CompanySettings from '../company/CompanySettings';
import Invoices from '../invoices/Invoices';
import IncomeBreakdownReport from '../reports/income_breakdown/IncomeBreakdownReport';

const AuthRoutes = () => {
  return (
    <div>
      <Navigation/>
      <AuthRoute path="/jobs" exact component={Jobs} />
      <AuthRoute path="/jobs/:jobNo" exact component={Job} />
      <AuthRoute path="/comingsoon" exact component={ComingSoon} />
      <AuthRoute path="/settings" exact component={CompanySettings} />
      <AuthRoute path="/invoices" exact component={Invoices} />
      <AuthRoute path="/income_breakdown" exact component={IncomeBreakdownReport} />
    </div>
  );
};

export default AuthRoutes;
