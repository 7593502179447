import {gql} from 'apollo-boost'

export default gql`
query ($firstName: String!, $lastName: String!) {
  findClients(firstName: $firstName, lastName: $lastName){
    id
    first_name
    last_name
    contact_methods{
      id
      contact_medium{
        id
        description
      }
      val
    }
  }
}
`