import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const ConfirmModal = ({isOpen, toggle, title, message, saving, onConfirm}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody style={{'white-space': 'pre-line'}}>{message}</ModalBody>
      <ModalFooter>
        <Button size="sm" color="default" disabled={saving} onClick={toggle}>Cancel</Button>
        <Button size="sm" color="primary" disabled={saving} onClick={onConfirm}>
          <FontAwesomeIcon
            color="#fff"
            icon={saving ? 'spinner' : 'check'}
            spin={saving}
          />{' '}
          {saving ? '' : 'Confirm'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  saving: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmModal;
