import {gql} from 'apollo-boost'

export default gql`
mutation ($settings: [UserPageObjectSettingInput]){
  upsertUserPageObjectSettings(settings: $settings){
    id
    user_id
    page_object_setting_id
    page_object_id
    page_setting_type_id
    page_id
    val
    order_by
    active
  }
}
`