import React from 'react';
import moment from 'moment';

const JobNoteListItem = ({id, user, note, created_at}) => {
  return (
    <div className="d-flex flex-column pt-2 pb-2 border-bottom">
      <span>{note}</span>
      <small className="text-muted font-italic">
        {moment(created_at).format('DD/MM/YYYY HH:mm')} - {user.forename} {user.surname}
      </small>
    </div>
  );
};

export default JobNoteListItem;
