import React from 'react';
import {Badge} from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';

const InvoicePaymentStatusBadge = ({datePaid}) => {
  return (
    <Badge color={datePaid ? 'info' : 'warning'}>
      {datePaid ? `Paid: ${moment(datePaid).format('DD/MM/YY')}` : 'Unpaid'}
    </Badge>
  );
};

InvoicePaymentStatusBadge.propTypes = {
  datePaid: PropTypes.string,
};

export default InvoicePaymentStatusBadge;
