import React from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import ContactMethodLabel from '../global/ContactMethodLabel';

const JobClientListItem = ({id, title, title_id, first_name, last_name, is_individual, company_name, contact_methods, onRemove, onEdit}) => {
  return (
    <div className="d-flex flex-row border-bottom justify-content-between pt-2 pb-2">
      <div>
        {is_individual ? <span>{title ? `${title.description} ` : ''}{first_name} {last_name}</span> : <span>{company_name}</span>}
        {contact_methods &&
        contact_methods.map((cm) => (
          <ContactMethodLabel
            key={cm.id}
            typeId={cm.contact_medium.id}
            label={cm.val}
          />
        ))
        }
      </div>
      <div className="d-flex flex-column">
        <Button
          className="mb-2"
          size="sm"
          color="link"
          onClick={() => onRemove(id)}
        >
          <FontAwesomeIcon
            color="#dc3545"
            icon="trash-alt"
          />
        </Button>
        <Button
          size="sm"
          color="link"
          onClick={() => onEdit({
            id,
            title_id,
            first_name,
            last_name,
            contact_methods,
            is_individual,
            company_name
          })}
        >
          <FontAwesomeIcon
            color="#007bff"
            icon="pen"
          />
        </Button>
      </div>

    </div>

  );
};

JobClientListItem.propTypes = {
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
};

export default JobClientListItem;
