import React, { useState } from "react";
import { Card, Form, FormGroup, Label, Input, Button, Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";

import authMutation from "../../mutations/authenticate";

import logo from "../../assets/images/login-logo.png";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authenticate, { loading, error }] = useMutation(authMutation);

  return (
    <div className="d-flex align-items-center flex-grow-1 flex-column justify-content-center container-fluid flex-fullheight">
      <Card className="d-flex flex-column shadow-sm p-3 mb-5 bg-white rounded login-card">
        <img alt="Minute Surveyor" src={logo} className="logo"></img>
        <Form>
          <FormGroup>
            <Label for="exampleEmail">Username</Label>
            <Input
              type="email"
              name="email"
              id="exampleEmail"
              placeholder="Username"
              value={username}
              onChange={(evt) => setUsername(evt.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">Password</Label>
            <Input
              type="password"
              name="password"
              id="examplePassword"
              placeholder="Password"
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
            />
          </FormGroup>
          {error && (
            <Alert color="danger">
              {error.graphQLErrors.map(({ message }, index) => {
                return <span key={index}>{message}</span>;
              })}
            </Alert>
          )}
          <Button
            size="sm"
            block
            color="primary"
            disabled={loading}
            onClick={() => {
              authenticate({ variables: { username, password } })
                .then(({ data }) => {
                  console.log("AUTH", data);
                  sessionStorage.setItem("token", data.authenticate);
                  props.history.push("/jobs");
                })
                .catch(console.log);
            }}
          >
            {loading ? "Logging in" : "Log in"}{" "}
            {loading && <FontAwesomeIcon color="#fff" icon="spinner" spin />}
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default withRouter(Login);
