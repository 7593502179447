import {gql} from 'apollo-boost';

export default gql `
mutation ($jobId:Float!){
  generateJobInvoice(jobId: $jobId) {
    invoice{
      id
      invoice_no
      date_paid
      date_created
      active
      emails{
        id
        date_sent
        email
        status
      }
    }
  }
}
`;
