import React from 'react';
import PropTypes from 'prop-types';

import JobClientListItem from './JobClientListItem';

const JobClientList = ({data, onRemove, onEdit}) => {
  return (
    <div className="d-flex flex-column">
      {data.map((client) => {
        return <JobClientListItem onRemove={onRemove} onEdit={onEdit} key={client.id} {...client} />;
      })}
    </div>
  );
};

JobClientList.propTypes = {
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
};

export default JobClientList;
