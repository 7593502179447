import React from 'react';
import {Input, Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import LookupSelect from '../components/global/LookupSelect'
import currencyFormater from './currencyFormater'
import dateFormatter from './dateFormatter'

const getColumns = (filters, settings, onPaidDateChange, onJobClick, onInvoiceClick) => {

  //if(!settings || settings.length === 0) return []

  return [
    {
      Header: 'Invoice No',
      id: 'invoice_no',
      accessor: ({id, date_paid, active, invoice_no}) => (
        <Button size="sm" color="link" onClick={() => onInvoiceClick({
          id,
          invoice_no,
          date_paid,
          active,
        })}>{invoice_no}</Button>
      ),
    },
    {
      Header: 'Is Paid',
      id: 'is_paid',
      accessor: ({date_paid}) => (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{height: '100%'}}
        >
          <FontAwesomeIcon 
            icon={date_paid ? 'check-circle' : 'times-circle'}
            className={date_paid ? 'text-success' : 'text-danger'}
          />
        </div>
      ),
      Filter: ({filter, onChange}) => (
        <div className="d-flex flex-column">
          <Input 
            bsSize="sm"
            type="select" 
            value={filters?.is_paid} 
            onChange={(evt) => onChange(evt.target.value)}
          >
            <option value="">Show All</option>
            <option value="1">Paid</option>
            <option value="0">Unpaid</option>
          </Input>
        </div>
      )
    },
    {
      Header: 'Date Paid',
      id: 'date_paid',
      minWidth: 150,
      accessor: (row) => {
        const {date_paid} = row;
        const val = date_paid ? dateFormatter.formatDateSQL(date_paid) : ''
        return (
          <Input
            bsSize="sm"
            type="date"
            value={val}
            onChange={async (evt) => {
              console.log(evt.target.value, row);
              await onPaidDateChange({...row, date_paid: evt.target.value})
            }}
          />
        )
      },
      Filter: ({filter, onChange}) => (
        <div className="d-flex flex-column">
          <Input 
            type="date" 
            value={filters?.date_paid?.start} 
            onChange={(evt) => 
                onChange({start: evt.target.value, end: filters?.date_paid?.end})}
          />
          <Input 
            type="date" 
            value={filters?.date_paid?.end} 
            onChange={(evt) => 
                onChange({end: evt.target.value, start: filters?.date_paid?.start})}
          />
        </div>
      ),
    },
    {
      Header: 'Date Generated',
      id: 'date_created',
      accessor: ({date_created}) => date_created ? dateFormatter.formatDate(date_created) : '',
      Filter: ({filter, onChange}) => (
        <div className="d-flex flex-column">
          <Input 
            type="date" 
            value={filters?.date_created?.start} 
            onChange={(evt) => 
                onChange({start: evt.target.value, end: filters?.date_created?.end})}
          />
          <Input 
            type="date" 
            value={filters?.date_created?.end} 
            onChange={(evt) => 
                onChange({end: evt.target.value, start: filters?.date_created?.start})}
          />
        </div>
      ),
    },
    {
      Header: 'Total Net',
      id: 'invoice_total_net',
      filterable: false,
      accessor: ({total_value_net}) => currencyFormater.formatCurrency(total_value_net),
    },
    {
      Header: 'Total Gross',
      id: 'invoice_total_gross',
      filterable: false,
      accessor: ({total_value_gross}) => currencyFormater.formatCurrency(total_value_gross),
    },
    {
      Header: 'Total VAT',
      id: 'invoice_total_vat',
      filterable: false,
      accessor: ({total_vat}) => currencyFormater.formatCurrency(total_vat),
    },
      {
        Header: 'Job No',
        id: 'job_no',
        accessor: ({job: {id, job_no}}) => (
          <Button size="sm" color="link" onClick={() => onJobClick(id)}>{job_no}</Button>
        ),
      },
      {
        Header: 'Job Types',
        id: 'job_types',
        accessor: 'job.job_breakdown',
        Cell: row => (
          <div className="d-flex flex-column">
            {row.value.map(({job_type, net, gross}) => 
              <span key={job_type.id}>{job_type.description}: {currencyFormater.formatCurrency(net)}</span>)}
          </div>
        )
      },
      {
        Header: 'Clients',
        id: 'clients',
        accessor: 'job.clients',
        Cell: row => (
          <div className="d-flex flex-column">
            {row.value.map(
              ({id, company_name, title, first_name, last_name, is_individual, contact_methods}) => 
                (
                  <div className="d-flex flex-column">
                    <span key={id}>{(is_individual) ? `${title?.description || ''} ${first_name} ${last_name}` : company_name}</span>
                    {contact_methods.map(({val}, index) => <small key={index}>{val}</small>)}
                  </div>
                  
  
                )
            )}
          </div>
        )
      },
      {
        Header: 'Status',
        id: 'job_status_id',
        arrayFilter: true,
        accessor: 'job.job_status.description',
        Filter: ({ filter, onChange }) => (
          <LookupSelect 
            lookup="job_statuses" 
            onChange={onChange}
            value={filters?.job_status_id ? filters?.job_status_id[0] : ''}
          />
        )
      },
      {
        Header: 'Source',
        id: 'job_source_id',
        accessor: 'job.job_source.description',
        Filter: ({ filter, onChange }) => (
          <LookupSelect 
            lookup="job_sources" 
            onChange={onChange}
            value={filters?.job_source_id ? filters?.job_source_id[0] : ''}
          />
        )
      },
      {
        Header: 'Quote Date',
        id: 'quote_date',
        accessor: ({job: {quote_date}}) => quote_date ? dateFormatter.formatDate(quote_date) : '',
        Filter: ({filter, onChange}) => (
          <div className="d-flex flex-column">
            <Input 
              type="date" 
              value={filters?.quote_date?.start} 
              onChange={(evt) => 
                  onChange({start: evt.target.value, end: filters?.quote_date?.end})}
            />
            <Input 
              type="date" 
              value={filters?.quote_date?.end} 
              onChange={(evt) => 
                  onChange({end: evt.target.value, start: filters?.quote_date?.start})}
            />
          </div>
        ),
      },
      {
        Header: 'Job Date',
        id: 'job_date',
        accessor: ({job: {job_date}}) => job_date ? dateFormatter.formatDate(job_date) : '',
        Filter: ({filter, onChange}) => (
          <div className="d-flex flex-column">
            <Input 
              type="date" 
              value={filters?.job_date?.start} 
              onChange={(evt) => 
                  onChange({start: evt.target.value, end: filters?.job_date?.end})}
            />
            <Input 
              type="date" 
              value={filters?.job_date?.end} 
              onChange={(evt) => 
                  onChange({end: evt.target.value, start: filters?.job_date?.start})}
            />
          </div>
        ),
      },
      {
        Header: 'Property Address',
        id: 'property_address_summary',
        accessor: 'job.property_address_summary',
      },
      
    ]
    /*.reduce((carry, column) => {
      if(settings.some(({page_object_setting_id, active}) => page_object_setting_id === column.id && active)) {
        carry.push({
          ...column, 
          order_by: settings
            .find(({page_object_setting_id}) => page_object_setting_id === column.id)
            .order_by
        })
      }
      return carry
    }, [])
    .sort((a, b) => a.order_by - b.order_by)*/
  
    

}

export default {
  getColumns,
}