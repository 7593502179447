import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const InvoiceInputModal = ({
  title,
  isOpen,
  toggle,
  date_paid,
  active,
  onSubmit,
  onChange,
  saving,
}) => {
  const formattedDatePaid = date_paid ? moment(date_paid).format('YYYY-MM-DD') : '';

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <AvForm
        onValidSubmit={() => {
          onSubmit({
            date_paid,
            active,
          });
        }}
      >
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalBody>
          <AvField
            name="datePaid"
            type="date"
            bsSize="sm"
            label="Date Paid"
            value={formattedDatePaid}
            onChange={(evt) => {
              onChange({key: 'date_paid', value: evt.target.value});
            }}
          />
          <AvField
            name="active"
            type="checkbox"
            label="Active"
            value={active}
            onChange={(evt) => {
              onChange({key: 'active', value: evt.target.checked});
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="link" size="sm" onClick={toggle}>
            Cancel
          </Button>
          <Button color="success" size="sm">
            Save{' '}
            <FontAwesomeIcon
              icon={saving ? 'spinner' : 'save'}
              spin={saving}
              color="white"
            />
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

InvoiceInputModal.propTypes = {
  title: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  date_paid: PropTypes.string,
  active: PropTypes.bool,
  saving: PropTypes.bool,
};

export default InvoiceInputModal;
