import React from 'react'
import {AvField} from 'availity-reactstrap-validation'
import {useQuery} from '@apollo/react-hooks'
import {gql} from 'apollo-boost'

const LookupSelect = (props) => {
  const query = gql`{
    ${props.lookup} {
      id
      description
    }
  }`

  const {loading, data} = useQuery(query)

  const records = (!loading) ? data[props.lookup] : []

  return (
    <AvField type="select" {...props} onChange={(evt) => props.onChange(evt.target.value)} bsSize="sm">
      <option value=""></option>
      {records.map(record => 
          <option key={record.id} value={record.id}>{record.description}</option>)}
    </AvField>
  )
}

export default LookupSelect