import React, {useState} from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import ReactTable from 'react-table';
// import withDraggableColumns from 'react-table-hoc-draggable-columns'
import debounce from 'lodash/debounce';
import {CSVLink} from 'react-csv';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'reactstrap';

import JobSimpleCreate from './JobSimpleCreate';

import ColumnSelector from '../global/ColumnSelector';

import jobsListHelper from '../../lib/jobListHelper';

import jobsQuery from '../../queries/jobs';
import settingsQuery from '../../queries/jobsPageSettings';
import userSettingsQuery from '../../queries/userPageObjectSettings';
import userPageObjectSettingsMutation from '../../mutations/upsertUserPageObjectSettings';
import generateJobNumberMutation from '../../mutations/generateJobNumber';

import 'react-table/react-table.css';
import 'react-table-hoc-draggable-columns/dist/styles.css';

// const ReactDraggableTable = withDraggableColumns(ReactTable)

const Jobs = () => {
  const [filters, setFilters] = useState({});
  const [limitOffset, setLimitOffset] = useState({});
  const [createJobOpen, setCreateJobOpen] = useState(false);

  const jobsRes = useQuery(jobsQuery, {variables: {filters, ...limitOffset}, fetchPolicy: 'network-only'});
  const settingsRes = useQuery(settingsQuery, {variables: {pageIds: ['jobs']}, fetchPolicy: 'network-only'});
  const userSettingsRes = useQuery(userSettingsQuery, {variables: {pageIds: ['jobs']}, fetchPolicy: 'network-only'});
  const [upsertUserPageObjectSettings, {loading}] = useMutation(userPageObjectSettingsMutation);
  const [generateJobNumber, jobNoRes] = useMutation(generateJobNumberMutation);

  const onFetchData = debounce((state, instance) => {
    const newFilters = state.filtered.reduce((carry, filter) => {
      switch (filter.id) {
        case 'job_status_id':
        case 'job_source_id':
        case 'assigned_user_id':
          carry[filter.id] = [filter.value];
          break;
        case 'quote_date':
        case 'job_date':
          carry[filter.id] = {...filter.value};
          break;
        default:
          carry[filter.id] = filter.value;
      }

      return carry;
    }, {});
    setFilters(newFilters);
    setLimitOffset({limit: state.pageSize, offset: state.pageSize * state.page});
  }, 500, {trailing: true});

  const jobs = (!jobsRes.loading) ? jobsRes.data.jobs : [];
  const totalJobs = (!jobsRes.loading) ? jobsRes.data.filter_jobs_total : 0;
  const settings = (!userSettingsRes.loading) ? userSettingsRes.data.user_page_object_settings.filter(({page_object_id}) => page_object_id === 'jobs_list') : [];
  const availableColumns = (!settingsRes.loading) ? settingsRes.data.page_objects.find(({id}) => id === 'jobs_list').settings : [];
  const columns = jobsListHelper.getColumns(filters, settings) || [];
  const {headers, data} = jobsListHelper.getCsvData(jobs, settings);

  const selectableColumns = availableColumns.map((column) => {
    const active = settings.some(({page_object_setting_id, active}) => column.id === page_object_setting_id && active);
    return {
      ...column,
      active,
    };
  });

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row justify-content-between p-2">
        <Button color="success" size="sm" onClick={async () => {
          if (!jobNoRes.loading) {
            await generateJobNumber({
              update: (proxy, {data: {generateJobNumber}}) => {
                // Read the data from our cache for this query.
                const data = proxy.readQuery({query: jobsQuery, variables: {filters, ...limitOffset}});
                // Write our data back to the cache with the new comment in it

                proxy.writeQuery({
                  query: jobsQuery,
                  variables: {filters, ...limitOffset},
                  data: {
                    filter_jobs_total: data.filter_jobs_total + 1,
                    jobs: [
                      {...generateJobNumber},
                      ...data.jobs,
                    ],
                  },
                });

                window.open(`/jobs/${generateJobNumber.id}`);
              },
            });
          }
        }}>
          Create Job{' '}
          <FontAwesomeIcon
            color="#fff"
            icon={jobNoRes.loading ? 'spinner' : 'plus'}
            spin={jobNoRes.loading}
          />
        </Button>
        <div className="d-flex flex-row">
          <CSVLink headers={headers} data={data} filename={'jobs.csv'}>
            <Button size="sm" color="link">
              <FontAwesomeIcon
                color="#000"
                icon="download"
              />
            </Button>
          </CSVLink>
          <ColumnSelector
            columns={selectableColumns}
            onChange={(data) => {
              const userSettingIndex = settings.findIndex((setting) => setting.page_object_setting_id === data.id);

              upsertUserPageObjectSettings({
                variables: {
                  settings: [{
                    page_object_setting_id: data.id,
                    page_object_id: 'jobs_list',
                    page_setting_type_id: 'column',
                    order_by: data.order_by,
                    active: data.active,
                  }],
                },
                optimisticResponse: {
                  __typename: 'Mutation',
                  upsertUserPageObjectSettings: [
                    ...settings.slice(0, userSettingIndex),
                    {
                      ...settings[userSettingIndex],
                      active: data.active,
                    },
                    ...settings.slice(userSettingIndex+1),
                  ],
                },
              });
            }}
          />
        </div>
      </div>

      <ReactTable
        draggableColumns= {{
          mode: 'reorder',
          draggable: columns.map((column) => column.id),
        }}
        data={jobs}
        pageSizeOptions={[5, 10, 20, 25, 50, 100, (totalJobs || 1)].sort((a, b) => a - b)}
        loading={jobsRes.loading || userSettingsRes.loading}
        style={{display: 'flex', flex: 1, fontSize: '14px'}}
        columns={columns}
        className="-striped -highlight"
        filterable
        manual
        pages={Math.ceil((totalJobs / limitOffset.limit) || 1)}
        onDraggedColumnChange={(columns) => {
          console.log('hwlllp');
        }}
        onFetchData={onFetchData}
        getTrProps={(state, rowInfo) => {
          return {
            onClick: (e) => {
              window.open(`/jobs/${rowInfo.original.id}`);
            },
          };
        }}
      />
    </div>

  );
};

export default Jobs;
