import {gql} from 'apollo-boost';

export default gql `
query ($id: String!, $companyId: String!){
  companySetting(id: "COMPANY_LOGO", companyId: $companyId){
    val
  }
  paymentInvoice(id: $id, companyId: $companyId){
    id
    invoice_no
    date_paid
    total_value_gross
    total_value_net
    total_vat
    job{
      invoicing_email
      job_types_summary
    }
  }
}
`;
