import {gql} from 'apollo-boost';

export default gql`
{
  companySettings{
    id: setting_id
    company_id
    setting_id
    setting{
      setting_type_id
      description
    }
    val
  }
}
`;
