import {gql} from 'apollo-boost'

export default gql`
query ($postcode: String!){
  searchPostcode(postcode: $postcode){
    postcode
    latitude
    longitude
    addresses{
      abode_name_no
      street
      city
      county
      postcode
      latitude
      longitude
      formatted_address
    }
  }
}
`