import React, {useState} from 'react';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/react-hooks';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {getIcon} from '../../lib/contactMediumHelper';
import query from '../../queries/contactMediums';

const ContactMediumDropdown = ({onSelect}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {data, loading} = useQuery(query, {fetchPolicy: 'network-only'});

  if (loading) return null;

  return (
    <ButtonDropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle caret color="primary" size="sm">
        Add Contact Details
      </DropdownToggle>
      <DropdownMenu>
        {data.contact_mediums.map(({id, description}) => {
          const {icon} = getIcon(id);
          return (
            <DropdownItem
              className="d-flex flex-row align-items-center"
              key={id}
              onClick={() => onSelect(id)}
            >
              <div
                className="
                  d-flex
                  align-items-center
                  justify-content-center
                  pr-2"
                style={{width: '14px'}}
              >
                <FontAwesomeIcon icon={icon} />
              </div>
              {description}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

ContactMediumDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default ContactMediumDropdown;
