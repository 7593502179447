import React from 'react';
import {Modal, ModalBody, ModalHeader, ModalFooter, Button} from 'reactstrap';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';

const AddressInputModal = (props) => {
  const {
    title,
    onChange,
    onSubmit,
    isOpen,
    toggle,
    id,
    abode_name_no,
    street,
    city,
    county,
    postcode,
  } = props;
  return (

    <Modal isOpen={isOpen} toggle={toggle}>
      <AvForm onValidSubmit={() => {
        onSubmit({
          id, abode_name_no, street, city, county, postcode,
        });
      }}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <AvField
            value={abode_name_no}
            bsSize="sm"
            label="Line 1"
            name="abodeNameNo"
            required
            onChange={(evt) => {
              onChange({key: 'abode_name_no', value: evt.target.value});
            }}
          />
          <AvField
            value={street}
            bsSize="sm"
            label="Line 2"
            name="street"
            onChange={(evt) => {
              onChange({key: 'street', value: evt.target.value});
            }}
          />
          <AvField
            value={city}
            bsSize="sm"
            label="City"
            name="city"
            onChange={(evt) => {
              onChange({key: 'city', value: evt.target.value});
            }}
          />
          <AvField
            value={county}
            bsSize="sm"
            label="County"
            name="county"
            onChange={(evt) => {
              onChange({key: 'county', value: evt.target.value});
            }}
          />
          <AvField
            value={postcode}
            bsSize="sm"
            label="Postcode"
            name="postcode"
            onChange={(evt) => {
              onChange({key: 'postcode', value: evt.target.value});
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button color="success" size="sm">Save</Button>
        </ModalFooter>
      </AvForm>
    </Modal>

  );
};

AddressInputModal.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  id: PropTypes.string,
  abode_name_no: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  county: PropTypes.string,
  postcode: PropTypes.string,
};

export default AddressInputModal;
