import React from 'react';
import {
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useMutation} from '@apollo/react-hooks';

import Badge from './InvoicePaymentStatusBadge';
import EmailList from '../emails/EmailList';

import downloadInvoiceMutation from '../../mutations/downloadInvoice';

const InvoiceListItem = ({invoice, onSend, onSelect}) => {
  const [downloadInvoice, {loading}] = useMutation(downloadInvoiceMutation);
  return (
    <div className={`p-2 ${!invoice.emails.length && 'border-bottom'}`}>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <Button color="link" className="p-0" onClick={() => onSelect(invoice)}>
          <h6 className="pb-0 mb-0">{invoice.invoice_no}</h6>
        </Button>

        <div className="d-flex flex-row">
          <Button color="link" size="sm" onClick={async () => {
            if (!loading) {
              const {data: {downloadJobInvoice}} = await downloadInvoice({
                variables: {
                  invoiceId: invoice.id,
                },
              });
              window.open(downloadJobInvoice, '_self');
            }
          }}>
            <FontAwesomeIcon
              icon={loading ? 'spinner' : 'download'}
              spin={loading}
            />
          </Button>
          <Button color="link" size="sm" onClick={() => {
            onSend(invoice.id);
          }}>
            <FontAwesomeIcon icon="paper-plane" />
          </Button>

        </div>

      </div>

      <Badge datePaid={invoice.date_paid} />

      <EmailList emails={invoice.emails} />
    </div>
  );
};

InvoiceListItem.propTypes = {
  invoice: PropTypes.object.isRequired,
  onSend: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default InvoiceListItem;
