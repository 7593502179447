import {gql} from 'apollo-boost';

export default gql `
mutation ($data: SettingInput!) {
  upsertCompanySetting(data: $data){
    id: setting_id
    company_id
    setting_id
    setting{
      setting_type_id
    }
    val
  }
}
`;
