import React from 'react';

import JobNoteListItem from './JobNoteListItem';

const JobNotes = ({notes}) => {
  return (
    <div>
      {notes.map((note, index) => {
        return <JobNoteListItem key={index} {...note} />;
      })}
    </div>
  );
};

export default JobNotes;
