import React, {useState} from 'react'
import {
  Popover,
  PopoverHeader,
  PopoverBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const ColumnSelector = ({columns, onChange}) => {
  const [settingsOpen, setSettingsOpen] = useState(false)

  return (
    <div>
      <Button id="settingsPopover" size="sm" color="link">
        <FontAwesomeIcon
          color="#000"
          icon="cogs"
        />
      </Button>
      <Popover trigger="legacy" placement="bottom" isOpen={settingsOpen} target="settingsPopover" toggle={() => setSettingsOpen(!settingsOpen)}>
        <PopoverHeader>Select Columns</PopoverHeader>
        <PopoverBody>
          <Form>
            {columns && columns.sort((a,b) => a.order_by - b.order_by).map(column => (<FormGroup key={column.id} check>
              <Label check>
                <Input 
                  type="checkbox" 
                  checked={column.active}
                  onChange={(evt) => onChange({...column, active: evt.target.checked})}
                />{' '}
                {column.label}
              </Label>
            </FormGroup>))}
          </Form>
        </PopoverBody>
      </Popover>
    </div>
  )
}

export default ColumnSelector