import React, {useState, useEffect} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {
  Row,
  Col,
  Table,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
} from 'recharts';
import sumBy from 'lodash/sumBy';

import Loading from '../../global/Loading';
import JobStatusesFilter from './JobStatusesFilter';

import currencyFormater from '../../../lib/currencyFormater';
import query from '../../../queries/invoiceBreakdownReport';

const renderActiveShape = (props) => {
  const {
    cx, cy, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, value,
  } = props;
  return (
    <g>
      <text x={cx} y={cy - 10} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
      <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fill={fill}>{currencyFormater.formatCurrency(value)}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const IncomeBreakdownReport = () => {
  const [jobSourceActiveIndex, setJobSourceActiveIndex] = useState(0);
  const [jobTypesActiveIndex, setJobTypesActiveIndex] = useState(0);
  const [userActiveIndex, setUserActiveIndex] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [createdAtFilter, setCreatedAtFilter] = useState({
    startDate: '',
    endDate: '',
  });
  const [jobDateFilter, setJobDateFilter] = useState({
    jobStartDate: '',
    jobEndDate: '',
  });

  const {data, loading, errors} =
    useQuery(query, {fetchPolicy: 'network-only', variables: {statuses, ...createdAtFilter, ...jobDateFilter}});

  if (!data && loading) return <Loading/>;


  const jobSourceData =
    data.invoiceTotalsByJobSource.map(({description, total}) => {
      return {
        name: description,
        value: total,
      };
    });
  const jobTypesData =
    data.invoiceTotalsByJobTypes.map(({description, total}) => {
      return {
        name: description,
        value: total,
      };
    });
  const userData =
    data.invoiceTotalsByAssignedUser.map(({user: {description}, total}) => {
      return {
        name: description,
        value: total,
      };
    });

  return (
    <div className="p-3">
      <Row>
        <Col>
          <h1>Income Breakdown Report</h1>
        </Col>
        <Col xs={8}>
          <div className="card card-body bg-light mt-3 mb-3">
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Job Created Date Start
                  </Label>
                  <Input
                    type="date"
                    value={createdAtFilter.startDate}
                    onChange={(evt) => {
                      setCreatedAtFilter({
                        ...createdAtFilter,
                        startDate: evt.target.value,
                      });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                  Job Created Date End
                  </Label>
                  <Input
                    type="date"
                    value={createdAtFilter.endDate}
                    onChange={(evt) => {
                      setCreatedAtFilter({
                        ...createdAtFilter,
                        endDate: evt.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>
                    Job Date Start
                  </Label>
                  <Input
                    type="date"
                    value={jobDateFilter.jobStartDate}
                    onChange={(evt) => {
                      setJobDateFilter({
                        ...jobDateFilter,
                        jobStartDate: evt.target.value,
                      });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                  Job Date End
                  </Label>
                  <Input
                    type="date"
                    value={jobDateFilter.jobEndDate}
                    onChange={(evt) => {
                      setJobDateFilter({
                        ...jobDateFilter,
                        jobEndDate: evt.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <Label>Job Statuses</Label>
                <JobStatusesFilter
                  values={statuses}
                  selectAll
                  onMultiChange={setStatuses}
                  onChange={({id, selected}) => {
                    if (selected) {
                      setStatuses([
                        ...statuses,
                        id,
                      ]);
                    } else {
                      setStatuses([
                        ...statuses.filter((statusID) => statusID !== id),
                      ]);
                    }
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
      </Row>
      <Row>
        <Col xs={12} sm={4}>
          <div style={{height: '300px'}}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  activeIndex={jobSourceActiveIndex}
                  onClick={(data, index) => {
                    setJobSourceActiveIndex(index);
                  }}
                  activeShape={renderActiveShape}
                  innerRadius={100}
                  outerRadius={120}
                  paddingAngle={3}
                  dataKey="value"
                  data={jobSourceData}
                  fill="#8884d8"
                  label={false}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>

          <Table hover>
            <thead>
              <tr>
                <th>Description</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                jobSourceData.map(({name, value}, index) => {
                  return (
                    <tr
                      className={jobSourceActiveIndex === index && 'table-active'}
                      key={index}
                      onClick={() => setJobSourceActiveIndex(index)}
                    >
                      <td>{name}</td>
                      <td>{currencyFormater.formatCurrency(value)}</td>
                    </tr>
                  );
                })
              }
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <strong>Total</strong>
                </td>
                <td>
                  <strong>{currencyFormater.formatCurrency(sumBy(jobSourceData, 'value'))}</strong>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
        <Col xs={12} sm={4}>
          <div style={{height: '300px'}}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  activeIndex={jobTypesActiveIndex}
                  onClick={(data, index) => {
                    setJobTypesActiveIndex(index);
                  }}
                  activeShape={renderActiveShape}
                  innerRadius={100}
                  outerRadius={120}
                  paddingAngle={3}
                  dataKey="value"
                  data={jobTypesData}
                  fill="#8884d8"
                  label={false}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>

          <Table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                jobTypesData.map(({name, value}, index) => {
                  return (
                    <tr
                      className={jobTypesActiveIndex === index && 'table-active'}
                      key={index}
                      onClick={() => setJobTypesActiveIndex(index)}
                    >
                      <td>{name}</td>
                      <td>{currencyFormater.formatCurrency(value)}</td>
                    </tr>
                  );
                })
              }
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <strong>Total</strong>
                </td>
                <td>
                  <strong>{currencyFormater.formatCurrency(sumBy(jobTypesData, 'value'))}</strong>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
        <Col xs={12} sm={4}>
          <div style={{height: '300px'}}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  activeIndex={userActiveIndex}
                  onClick={(data, index) => {
                    setUserActiveIndex(index);
                  }}
                  activeShape={renderActiveShape}
                  innerRadius={100}
                  outerRadius={120}
                  paddingAngle={3}
                  dataKey="value"
                  data={userData}
                  fill="#8884d8"
                  label={false}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>

          <Table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                userData.map(({name, value}, index) => {
                  return (
                    <tr
                      className={userActiveIndex === index && 'table-active'}
                      key={index}
                      onClick={() => setUserActiveIndex(index)}
                    >
                      <td>{name}</td>
                      <td>{currencyFormater.formatCurrency(value)}</td>
                    </tr>
                  );
                })
              }
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <strong>Total</strong>
                </td>
                <td>
                  <strong>{currencyFormater.formatCurrency(sumBy(userData, 'value'))}</strong>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default IncomeBreakdownReport;
