import React from 'react';
import {Row, Col} from 'reactstrap';
import {AvField} from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';

import AvLookupSelectField from '../global/AvLookupSelectField';

const ClientCompanyFormFields = (props) => {
  const {
    onChange,
    company_name,
    email,
    phone,
  } = props;
  return (
    <div>
      <Row form>
        
        <Col>
          <AvField
            value={company_name}
            bsSize="sm"
            label="Company Name *"
            name="company_name"
            required
            onChange={(evt) => {
              onChange({key: 'company_name', value: evt.target.value});
            }}
          />
        </Col>
        
      </Row>
      <Row form>
        <Col>
          <AvField
            value={email}
            type="email"
            bsSize="sm"
            label="Email"
            name="email"
            onChange={(evt) => {
              onChange({key: 'email', value: evt.target.value});
            }}
          />
        </Col>
        <Col>
          <AvField
            value={phone}
            bsSize="sm"
            label="Phone"
            name="phone"
            onChange={(evt) => {
              onChange({key: 'phone', value: evt.target.value});
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

ClientCompanyFormFields.propTypes = {
  onChange: PropTypes.func.isRequired,
  title_id: PropTypes.string.isRequired,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
};

export default ClientCompanyFormFields;
