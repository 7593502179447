import React from 'react';
import {AvField} from 'availity-reactstrap-validation';
import {useQuery} from '@apollo/react-hooks';
import {gql} from 'apollo-boost';

const LookupSelect = (props) => {
  const query = gql`{
    job_sources{
      id
      description
      invoicing_email
      purchase_order_no
    }
  }`;

  const {loading, data} = useQuery(query);

  const records = (!loading) ? data.job_sources : [];

  return (
    <AvField
      type="select"
      {...props}
      onChange={(evt) => {
        const record = records.find((record) => record.id == evt.target.value);
        props.onChange(evt.target.value, record);
      }}
      bsSize="sm"
    >
      <option value=""></option>
      {records.map((record) =>
        <option key={record.id} value={record.id}>{record.description}</option>)}
    </AvField>
  );
};

export default LookupSelect;
