import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';

const CompanyDocumentEmailModal = ({
  title,
  message,
  clients,
  invoicingEmail,
  onSend,
  sending,
  isOpen,
  toggle,
}) => {
  const [selectedEmails, setSelectedEmails] = useState([]);

  useEffect(() => {
    setSelectedEmails([]);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>{title}</ModalHeader>

      <ModalBody>
        <span>{message}</span>
        <hr/>
        {clients
            .filter((client) => {
              return (client.contact_methods &&
                !!client.contact_methods.length &&
                client.contact_methods.some(
                    ({contact_medium}) => parseInt(contact_medium.id) === 2)
              );
            })
            .map((client, index) => {
              return (
                <div key={index} className="pb-3 pt-3 border-bottom">
                  {client.is_individual ?
                    <Label tag="h6" className="pb-0 mb-0">{client.title ? `${client.title.description} ` : ''} {client.first_name} {client.last_name}</Label> :
                    <Label tag="h6" className="pb-0 mb-0">{client.company_name}</Label>
                  }
                  {client.contact_methods
                      .filter((cm) => parseInt(cm.contact_medium.id) === 2)
                      .map((cm) => {
                        return (
                          <FormGroup check key={cm.id}>
                            <Label check>
                              <Input type="checkbox" onChange={(evt) => {
                                if (evt.target.checked) {
                                  setSelectedEmails([
                                    ...selectedEmails,
                                    cm.val,
                                  ]);
                                } else {
                                  setSelectedEmails([
                                    ...selectedEmails.filter((email) => email !== cm.val),
                                  ]);
                                }
                              }} />{' '}
                              {cm.val}
                            </Label>
                          </FormGroup>
                        );
                      })}
                </div>
              );
            })}
        {invoicingEmail && (
          <div className="pt-3 pb-3">
            <Label tag="h6" className="pb-0 mb-0">Invoicing Email</Label>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" onChange={(evt) => {
                  if (evt.target.checked) {
                    setSelectedEmails([
                      ...selectedEmails,
                      invoicingEmail,
                    ]);
                  } else {
                    setSelectedEmails([
                      ...selectedEmails.filter((email) => email !== invoicingEmail),
                    ]);
                  }
                }} />{' '}
                {invoicingEmail}
              </Label>
            </FormGroup>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button size="sm" color="default" disabled={sending} onClick={toggle}>Cancel</Button>
        <Button
          size="sm"
          color="success"
          disabled={sending || !selectedEmails.length}
          onClick={() => {
            onSend(selectedEmails);
          }}
        >
          {sending ? 'Sending...' : 'Send'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CompanyDocumentEmailModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  clients: PropTypes.array,
  onSend: PropTypes.func.isRequired,
  sending: PropTypes.bool,
  invoicingEmail: PropTypes.string,
};

export default CompanyDocumentEmailModal;
