import {gql} from 'apollo-boost';

export default gql `
query ($startDate: String, $endDate: String, $jobStartDate: String, $jobEndDate: String, $statuses: [Float]){
  invoiceTotalsByJobSource(startDate: $startDate, endDate: $endDate, jobStartDate: $jobStartDate, jobEndDate: $jobEndDate, statuses: $statuses){
    description
    total
  }
  invoiceTotalsByJobTypes(startDate: $startDate, endDate: $endDate, jobStartDate: $jobStartDate, jobEndDate: $jobEndDate, statuses: $statuses){
    description
    total
  }
  invoiceTotalsByAssignedUser(startDate: $startDate, endDate: $endDate, jobStartDate: $jobStartDate, jobEndDate: $jobEndDate, statuses: $statuses){
   	user{
      id
      description
    }
    total
  }
}
`;
