import React, {useEffect} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {FormGroup, Label, Input} from 'reactstrap';
import PropTypes from 'prop-types';

import query from '../../../queries/jobStatuses';

const JobStatusesFilter = ({selectAll, values, onChange, onMultiChange}) => {
  const {data, loading, errors} = useQuery(query, {fetchPolicy: 'network-only'});

  useEffect(() => {
    if (data && data.hasOwnProperty('job_statuses')) {
      onMultiChange(data.job_statuses.map(({id}) => id));
    }
  }, [data]);

  if (loading) return null;

  return (
    <div>
      {data.job_statuses.map(({id, description}) => {
        return (
          <FormGroup check key={id}>
            <Label check>
              <Input
                type="checkbox"
                checked={values.includes(id)}
                onChange={(evt) => onChange({id, selected: evt.target.checked})}
              />{' '}
              {description}
            </Label>
          </FormGroup>
        );
      })}
    </div>
  );
};

JobStatusesFilter.propTypes = {
  selectAll: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  onMultiChange: PropTypes.func.isRequired,
};

export default JobStatusesFilter;
