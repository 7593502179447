import React from 'react';
import {Row, Col, Input} from 'reactstrap';
import Textarea from 'react-expanding-textarea';

const CompanySettingListItem = ({setting_id, setting, val, onChange}) => {
  const getInput = () => {
    switch (setting.setting_type_id) {
      case 2:
        return (
          <Textarea
            className="form-control form-control-sm"
            defaultValue={val}
            onChange={(evt) => onChange({setting_id, val: evt.target.value})}
          />
        );
      case 3:
        return (
          <Input
            type="number"
            defaultValue={val}
            onChange={(evt) => onChange({setting_id, val: evt.target.value})}
          />
        );
      case 4:
        // this will be an image picker eventually
        return (
          <Textarea
            className="form-control form-control-sm"
            defaultValue={val}
            onChange={(evt) => onChange({setting_id, val: evt.target.value})}
          />
        );
      default:
        return (
          <Input
            defaultValue={val}
            onChange={(evt) => onChange({setting_id, val: evt.target.value})}
          />
        );
    }
  };

  return (
    <Row className="border-bottom p-3">
      <Col className="d-flex flex-column justify-content-center">
        <div className="d-flex flex-column">
          <h6 className="p-0 m-0">{setting.description}</h6>
        </div>
      </Col>
      <Col>
        {getInput()}
      </Col>
    </Row>
  );
};

export default CompanySettingListItem;
