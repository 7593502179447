import React from 'react';
import {Row, Col} from 'reactstrap';

import currencyFormater from '../../lib/currencyFormater';

const Invoice = ({
  headerImageUrl,
  invoiceNo,
  jobTypesSummary,
  totalValueNet,
  totalVat,
  totalValueGross,
  datePaid,
}) => {
  return (
    <div>
      <div className="d-flex flex-row align-items-center">
        <img width="200" src={headerImageUrl} alt="Card image cap" />
        <div className="d-flex flex-column ml-3">
          <h5>
              Your Invoice
          </h5>
          <h6 className="text-muted">
            {invoiceNo}
          </h6>
        </div>
      </div>
      <div className="mt-4 mb-4" style={{position: 'relative'}}>
        <Row>
          <Col xs={9} className="border-top border-bottom pt-4 pb-4">
            {jobTypesSummary}
          </Col>
          <Col xs={3} className="border-top border-bottom pt-4 pb-4">
            {currencyFormater.formatCurrency(totalValueNet)}
          </Col>
          <Col xs={9} className="pt-3 pb-3 flex-end" style={{textAlign: 'right'}}>
              Sub-total
          </Col>
          <Col xs={3} className="border-bottom pt-3 pb-3">
            {currencyFormater.formatCurrency(totalValueNet)}
          </Col>
          <Col xs={9} className="pt-3 pb-3" style={{textAlign: 'right'}}>
              VAT @ 20%
          </Col>
          <Col xs={3} className="border-bottom pt-3 pb-3">
            {currencyFormater.formatCurrency(totalVat)}
          </Col>
          <Col xs={9} className="pt-3 pb-3" style={{textAlign: 'right'}}>
              Total
          </Col>
          <Col xs={3} className="border-bottom pt-3 pb-3">
            {currencyFormater.formatCurrency(totalValueGross)}
          </Col>
        </Row>
        {datePaid && (<div
          className="d-flex justify-content-center align-items-center"
          style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
          <div
            className="
                rotate-30
                d-flex
                border
                border-danger
                rounded
                align-items-center
                justify-content-center
                pl-4
                pr-4
              "
            style={{
              height: '100px',
            }}
          >
            <h1 className="text-danger" style={{fontSize: '5rem'}}>
              Paid
            </h1>
          </div>

        </div>)}
      </div>
    </div>
  );
};

export default Invoice;
