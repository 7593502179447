import React, {useState} from 'react';
import ReactTable from 'react-table';
import {useQuery, useMutation} from '@apollo/react-hooks';
import debounce from 'lodash/debounce';
import 'react-table/react-table.css';
import 'react-table-hoc-draggable-columns/dist/styles.css';

import InvoiceInputModal from './InvoiceInputModal';

import invoiceListHelper from '../../lib/invoiceListHelper';

import invoicesQuery from '../../queries/invoices';
import updateInvoicePaidDateMutation
  from '../../mutations/updateInvoicePaidDate';
import updateInvoiceMutation from '../../mutations/updateInvoice';


const Invoices = () => {
  const [invoiceFilters, setInvoiceFilters] = useState({});
  const [jobFilters, setJobFilters] = useState({});
  const [limitOffset, setLimitOffset] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [editInvoice, setEditInvoice] = useState(null);

  const [updatePaidDate, {loading}] = useMutation(updateInvoicePaidDateMutation);
  const [updateInvoice, uiRes] = useMutation(updateInvoiceMutation);

  const invoicesRes = useQuery(
      invoicesQuery,
      {
        variables: {
          invoiceFilters,
          jobFilters,
          ...limitOffset,
        },
        fetchPolicy: 'network-only',
      });

  const onFetchData = debounce((state, instance) => {
    const iFilters = state.filtered.reduce((carry, filter) => {
      switch (filter.id) {
        case 'date_paid':
        case 'date_created':
          carry[filter.id] = {...filter.value};
          break;
        case 'invoice_no':
        case 'is_paid':
          carry[filter.id] = filter.value;
      }

      return carry;
    }, {});

    const jFilters = state.filtered.reduce((carry, filter) => {
      switch (filter.id) {
        case 'job_status_id':
        case 'job_source_id':
        case 'assigned_user_id':
          carry[filter.id] = [filter.value];
          break;
        case 'quote_date':
        case 'job_date':
          carry[filter.id] = {...filter.value};
          break;
        case 'job_no':
        case 'job_types':
        case 'clients':
        case 'property_address_summary':
          carry[filter.id] = filter.value;
      }

      return carry;
    }, {});
    setInvoiceFilters(iFilters);
    setJobFilters(jFilters);
    setLimitOffset({limit: state.pageSize, offset: state.pageSize * state.page});
  }, 500, {trailing: true});

  const invoices = (!invoicesRes.loading) ? invoicesRes.data.filter_invoices : [];
  const totalInvoices = (!invoicesRes.loading) ? invoicesRes.data.filter_invoices_total : 0;

  const columns = invoiceListHelper.getColumns(
      {...invoiceFilters, ...jobFilters},
      [],
      async (data) => {
        try {
          await updatePaidDate({
            variables: {
              id: data.id,
              datePaid: data.date_paid,
            },
            optimisticResponse: {
              __typename: 'Mutation',
              updateInvoicePaidDate: {
                ...data,
              },
            },
          });
        } catch (err) {
          alert(err.message);
        }
      },
      (jobId) => window.open(`/jobs/${jobId}`),
      (invoice) => {
        setEditInvoice(invoice);
        setModalOpen(true);
      },

  );

  return (
    <div className="d-flex flex-column">
      <div className="p-3">
        <h1>Invoices</h1>
      </div>
      <ReactTable
        loading={invoicesRes.loading}
        data={invoices}
        manual
        onFetchData={onFetchData}
        style={{display: 'flex', flex: 1, fontSize: '14px'}}
        className="-striped -highlight"
        pages={Math.ceil((totalInvoices / limitOffset.limit) || 1)}
        filterable
        columns={columns}
      />
      <InvoiceInputModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        title={`Edit Invoice: ${editInvoice?.invoice_no}`}
        {...editInvoice}
        onChange={({key, value}) => {
          setEditInvoice({
            ...editInvoice,
            [key]: value,
          });
        }}
        saving={uiRes.loading}
        onSubmit={async () => {
          try {
            await updateInvoice({
              variables: {
                data: {
                  id: editInvoice.id,
                  date_paid: editInvoice.date_paid,
                  active: editInvoice.active,
                },
              },
              update: (proxy, {data: {updateInvoice}}) => {
                // Read the data from our cache for this query.
                const data = proxy.readQuery({
                  query: invoicesQuery,
                  variables: {
                    invoiceFilters,
                    jobFilters,
                    ...limitOffset,
                  }});
                // Write our data back to the cache with the new comment in it
                const index = data.filter_invoices.findIndex(({id}) => updateInvoice.id === id);

                const newData = (!updateInvoice.active) ?
                  data.filter_invoices.filter(({id}) => id !== updateInvoice.id) :
                  ([
                    ...data.filter_invoices.slice(0, index),
                    {...updateInvoice},
                    ...data.filter_invoices.slice(index+1),
                  ]);

                proxy.writeQuery({
                  query: invoicesQuery,
                  variables: {
                    invoiceFilters,
                    jobFilters,
                    ...limitOffset,
                  },
                  data: {
                    filter_invoices: [
                      ...newData,
                    ],
                  }});
              },
            });

            setModalOpen(false);
            setEditInvoice(null);
          } catch (err) {
            alert(err.message);
          }
        }}
      />
    </div>
  );
};

export default Invoices;
