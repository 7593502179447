import {
  gql,
} from 'apollo-boost';

export default gql `
{
  standard_document_groups {
    id
    description
    active
  }
}
`;
