import {gql} from 'apollo-boost'

export default gql`
query ($contactMethod: String!){
  findContactMethodClients(contactMethod: $contactMethod){
    id
    first_name
    last_name
    contact_methods{
      id
      contact_medium{
        id
        description
      }
      val
    }
  }
}
`