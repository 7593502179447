import React from 'react';
import {Row, Col} from 'reactstrap';
import {AvField} from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';

import AvLookupSelectField from '../global/AvLookupSelectField';

const ClientFormFields = (props) => {
  const {
    onChange,
    title_id,
    first_name,
    last_name,
    email,
    phone,
  } = props;
  return (
    <div>
      <Row form>
        <Col xs={2}>
          <AvLookupSelectField
            name="title_id"
            value={title_id}
            bsSize="sm"
            lookup="titles"
            label="Title"
            onChange={(title_id) => {
              onChange({key: 'title_id', value: title_id});
            }}
          />
        </Col>
        <Col>
          <AvField
            value={first_name}
            bsSize="sm"
            label="First Name *"
            name="first_name"
            required
            onChange={(evt) => {
              onChange({key: 'first_name', value: evt.target.value});
            }}
          />
        </Col>
        <Col>
          <AvField
            value={last_name}
            bsSize="sm"
            label="Last Name *"
            name="last_name"
            required
            onChange={(evt) => {
              onChange({key: 'last_name', value: evt.target.value});
            }}
          />
        </Col>
      </Row>
      <Row form>
        <Col>
          <AvField
            value={email}
            type="email"
            bsSize="sm"
            label="Email"
            name="email"
            onChange={(evt) => {
              onChange({key: 'email', value: evt.target.value});
            }}
          />
        </Col>
        <Col>
          <AvField
            value={phone}
            bsSize="sm"
            label="Phone"
            name="phone"
            onChange={(evt) => {
              onChange({key: 'phone', value: evt.target.value});
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

ClientFormFields.propTypes = {
  onChange: PropTypes.func.isRequired,
  title_id: PropTypes.string.isRequired,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
};

export default ClientFormFields;
