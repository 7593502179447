import {gql} from 'apollo-boost';

export default gql `
{
  contact_mediums{
    id
    description
  }
}
`;
