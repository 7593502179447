import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getIcon} from '../../lib/contactMediumHelper';

const ContactMethodLabel = ({typeId, label}) => {
  const icon = getIcon(typeId);

  return (
    <div className="d-flex flex-row align-items-center">
      <div style={{width: '20px'}}>
        <FontAwesomeIcon
          color={icon.colour}
          icon={icon.icon}
        />{' '}
      </div>
      <small>{label}</small>
    </div>
  );
};

export default ContactMethodLabel;
