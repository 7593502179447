import React from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {useMutation} from '@apollo/react-hooks';

import EmailList from '../emails/EmailList';

import downloadJobUploadMutation from '../../mutations/downloadJobUpload';

const UploadsListItem = ({upload, onSend}) => {
  const [download, {loading}] = useMutation(downloadJobUploadMutation);

  return (
    <div className="d-flex flex-column p-2">
      <div className="d-flex flex-row justify-content-between align-center">
        <h6>{upload.name}</h6>

        <div className="d-flex flex-row">
          <Button
            color="link"
            size="sm"
            disabled={loading}
            onClick={async () => {
              try {
                const {data: {downloadJobUpload}} = await download({
                  variables: {
                    uploadId: upload.id,
                  },
                });
                window.open(downloadJobUpload, '_self');
              } catch (err) {
                alert(err.message);
              }
            }}
          >
            <FontAwesomeIcon
              icon={loading ? 'spinner' : 'download'}
              spin={loading}
              className="text-primary"
            />
          </Button>
          <Button
            color="link"
            size="sm"
            disabled={loading}
            onClick={() => onSend(upload)}
          >
            <FontAwesomeIcon
              icon={loading ? 'spinner' : 'paper-plane'}
              spin={loading}
              className="text-primary"
            />
          </Button>
        </div>

      </div>
      <EmailList emails={upload.job_emails} />
    </div>

  );
};

UploadsListItem.propTypes = {
  upload: PropTypes.object.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default UploadsListItem;
