import React, {useState, useEffect} from 'react';
import {Modal, ModalBody, ModalHeader, ModalFooter, Button, FormGroup, Label, Input} from 'reactstrap';
import {AvForm} from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';

import ClientFormFields from '../clients/ClientFormFields';
import ClientCompanyFormFields from '../clients/ClientCompanyFormFields';

const JobClientInputModal = (props) => {

  const {
    onClearUncommonDetails,
    onChange,
    onSubmit,
    isOpen,
    toggle,
    title,
    title_id,
    first_name,
    last_name,
    email,
    phone,
    company_name
  } = props;

  const [isCompany, setIsCompany] = useState(false);
  
  return (

    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <AvForm onValidSubmit={() => {
        onSubmit({
          title_id,
          first_name,
          last_name,
          email,
          phone,
          company_name,
          is_individual: !isCompany
        });
      }}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <FormGroup check className="mb-4">
            <Label check>
              <Input
                type="checkbox"
                value={isCompany}
                defaultChecked={isCompany}
                onChange={(evt) => {
                  setIsCompany(evt.target.checked);
                  onClearUncommonDetails();
                }}
              />{' '}
              Client is a company
            </Label>
          </FormGroup>
          {
          !isCompany ? 
            <ClientFormFields {...props} onChange={onChange} /> : 
            <ClientCompanyFormFields {...props} onChange={onChange} />
          }
        </ModalBody>

        <ModalFooter>
          <Button color="success" size="sm">Save</Button>
        </ModalFooter>
      </AvForm>
    </Modal>

  );
};

JobClientInputModal.propTypes = {
  onClearUncommonDetails: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  title_id: PropTypes.string.isRequired,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  company_name: PropTypes.string
};

export default JobClientInputModal;
