import {
  gql,
} from 'apollo-boost';

export default gql `
mutation($data: JobNoteInput!) {
  upsertJobNote(data: $data) {
    id
    user {
      id
      forename
      surname
    }
    note
    created_at
  }
}
`;