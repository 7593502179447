import {gql} from 'apollo-boost'

export default gql`
query($pageIds: [String]){
  page_objects(pageIds: $pageIds){
    id
    page_id
    description
    settings{
      id
      page_setting_type_id
      label
      description
      order_by
    }
  }
}
`