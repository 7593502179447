import {gql} from 'apollo-boost';

export default gql`
mutation ($data: InvoiceInput!) {
  updateInvoice(data: $data){
    id
    invoice_no
    total_value_gross
    total_value_net
    total_vat
    date_paid
    date_created
    active
    job {
      id
      job_no
      job_status {
        id
        description
      }
      job_source {
        id
        description
      }
      invoicing_email
      quest_ref
      terms_received
      quote_date
      property_type {
        id
        description
      }
      property_condition {
        id
        description
      }
      property_age {
        id
        description
      }
      property_address_summary
      number_of_beds
      purchase_price
      assigned_user {
        id
        forename
        surname
      }
      job_date
      created_at
      job_addresses {
        job_address_type {
          id
          description
        }
        address {
          id
          abode_name_no
          street
          city
          county
          postcode
        }
      }
      total_net
      total_gross
      total_vat
      job_breakdown: job_breakdown_active {
        job_type {
          id
          description
        }
        net
        gross
      }
      clients {
        id
        company_name
        title {
          id
          description
        }
        first_name
        last_name
        is_individual
        contact_methods {
          id
          contact_medium {
            id
            description
          }
          val
        }
      }
    }
  }
}
`;
