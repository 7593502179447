import React from 'react';
import {useParams} from 'react-router-dom';
import {Elements, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {
  Button,
  Alert,
} from 'reactstrap';

import Loading from '../global/Loading';
import Invoice from './Invoice';

import query from '../../queries/paymentInvoice';
import setupPaymentIntentMutation from '../../mutations/setupPaymentIntent';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Payment = (props) => {
  const [clientSecret, setClientSecret] = React.useState(null);
  const {companyId, invoiceId} = useParams();
  const {data, loading, error} = useQuery(
      query,
      {
        variables: {
          id: invoiceId,
          companyId,
        },
        fetchPolicy: 'network-only',
      },
  );
  const [setupPaymentIntent, piRes] = useMutation(setupPaymentIntentMutation);

  const initClientSecret = async () => {
    const {data} = await setupPaymentIntent({variables: {invoiceId}});
    setClientSecret(data.setupPaymentIntent);
  };

  React.useEffect(() => {
    initClientSecret();
  }, []);

  if (loading && piRes.loading) return <Loading/>;

  if (!data || !data.paymentInvoice || error) {
    return <Alert color="danger m-4">Error: Record doesn't exist</Alert>;
  }

  return (
    <div className="d-flex justify-content-center pt-3">

      <div className="d-flex flex-column border p-5" style={{width: '100%', maxWidth: '968px'}}>

        <Invoice
          headerImageUrl={data.companySetting.val}
          invoiceNo={data.paymentInvoice.invoice_no}
          jobTypesSummary={data.paymentInvoice.job.job_types_summary}
          totalValueNet={data.paymentInvoice.total_value_net}
          totalVat={data.paymentInvoice.total_vat}
          totalValueGross={data.paymentInvoice.total_value_gross}
          datePaid={data.paymentInvoice.date_paid}
        />

        {!data.paymentInvoice.date_paid && clientSecret && (
          <Elements stripe={stripePromise} options={{clientSecret}}>
            <StripePaymentForm />
          </Elements>
        )}
      </div>

    </div>

  );
};

const StripePaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const {invoiceId, companyId} = useParams();
  const [paymentProcessing, setPaymentProcessing] = React.useState(false);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setPaymentProcessing(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setPaymentProcessing(false);
      return;
    }

    const result = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/payments/${companyId}/${invoiceId}/success`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      alert(result.error.message);
      setPaymentProcessing(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button block size="lg" color="success" className="mt-3" disabled={paymentProcessing}>
        {paymentProcessing ? 'Processing...' : 'Pay with Card'}
      </Button>
    </form>
  );
};

export default Payment;
