import React from 'react';

const ComingSoon = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <h1>Coming Soon</h1>
    </div>
  );
};

export default ComingSoon;
