import {gql} from 'apollo-boost';

export default gql `
query {
  job_statuses{
    id
    description
  }
}
`;
