import React from 'react';
import PropTypes from 'prop-types';

import InvoiceListItem from './JobInvoiceListItem';

const InvoiceList = ({invoices, onSend, onSelect}) => {
  return (
    <div>
      {invoices.map((invoice, index) => {
        return (
          <InvoiceListItem
            key={index}
            onSend={onSend}
            onSelect={onSelect}
            invoice={invoice}
          />
        );
      })}
    </div>
  );
};

InvoiceList.propTypes = {
  invoices: PropTypes.array,
  onSend: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default InvoiceList;
