import React, {useCallback, useState, useRef} from 'react';
import {useParams} from 'react-router-dom'
import {useQuery, useMutation} from '@apollo/react-hooks';
import {
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  CardBody,
  Input,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import { AvForm, AvField, AvCheckbox } from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import moment from 'moment';
import debounce from 'lodash/debounce';
import Textarea from 'react-expanding-textarea';
import axios from 'axios';

import Loading from '../global/Loading'
import AvLookupSelectField from '../global/AvLookupSelectField'
import JobSourceField from '../global/AvJobSourceSelectField';
import PostcodeSearch from '../global/PostcodeSearch'
import AddressInputModal from '../address/AddressInputModal';
import JobClientInputModal from '../jobs/JobClientInputModal';
import JobClientList from './JobClientList';
import NoteInputModal from '../global/NoteInputModal';
import JobNotes from './JobNotes';
import ConfirmModal from '../global/ConfirmModal';
import JobTypesInput from './JobTypesInput';
import CompanyDocumentsList from '../company_documents/CompanyDocumentListContainer';
import InvoiceList from '../invoices/JobInvoiceList';
import ClientInputModal from '../clients/ClientInputModal';
import CompanyDocumentEmailModal from '../company_documents/CompanyDocumentEmailModal';
import InvoiceInputModal from '../../components/invoices/InvoiceInputModal';
import UploadsList from '../uploads/UploadsList';
import YesNoModal from '../global/YenNoModal';

import jobQuery from '../../queries/job';
import jobMutation from '../../mutations/updateJob'
import jobAddressMutation from '../../mutations/upsertJobAddress'
import jobClientMutation from '../../mutations/createJobClient'
import jobNoteMutation from '../../mutations/upsertJobNote'
import removeJobClientMutation from '../../mutations/removeJobClient';
import upsertJobTypeMutation from '../../mutations/upsertJobTypeBreakdown';
import sendJobInvoiceMutation from '../../mutations/sendJobInvoice';
import sendDocumentGroupMutation from '../../mutations/sendDocumentGroup';
import generateInvoiceMutation from '../../mutations/generateInvoice';
import updateJobClientMutation from '../../mutations/updateJobClient';
import updateInvoiceMutation from '../../mutations/updateInvoice';
import getS3SignedUrlMutation from '../../mutations/getS3SignedUrl';
import createJobUploadMutation from '../../mutations/createJobUpload';
import sendJobUploadMutation from '../../mutations/sendJobUpload';

import formater from '../../lib/currencyFormater';


function useDebounce(callback, delay) {
	const debouncedFn = useCallback(
		debounce((...args) => callback(...args), delay),
		[delay], // will recreate if delay changes
	);
	return debouncedFn;
}

const defaultAddress = {
  abode_name_no: '',
  street: '',
  city: '',
  county: '',
  postcode: '',
};

const defaultClient = {
  title_id: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
}

const Job = () => {
  const { jobNo } = useParams();
  let uploadInput = useRef();

  const [removeJobClientID, setRemoveJobClientID] = useState(null);
  const [sendInvoiceID, setSendInvoiceID] = useState(null);
  const [sendDocument, setSendDocument] = useState(null);
  const [surveyAddressModalOpen, setSurveyAddressModalOpen] = useState(false);
  const [billingAddressModalOpen, setBillingAddressModalOpen] = useState(false);
  const [clientModalOpen, setClientModalOpen] = useState(false);
  const [clientEditModalOpen, setClientEditModalOpen] = useState(false);
  const [clientEdit, setClientEdit] = useState(null);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [removeJobClientConfirmModalOpen, setRemoveJobClientConfirmModalOpen] = useState(false);
  const [confirmSendInvoiceModalOpen, setConfirmSendInvoiceModalOpen] = useState(false);
  const [confirmSendDocumentModalOpen, setConfirmSendDocumentModalOpen] = useState(false);
  const [confirmGenerateInvoiceModalOpen, setConfirmGenerateInvoiceModalOpen] = useState(false);
  const [surveyAddress, setSurveyAddress] = useState(defaultAddress);
  const [billingAddress, setBillingAddress] = useState(defaultAddress);
  const [client, setClient] = useState(defaultClient);
  const [note, setNote] = useState('');
  const [editInvoiceModalOpen, setEditInvoiceModalOpen] = useState(false);
  const [editInvoice, setEditInvoice] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [sendUploadModalOpen, setSendUploadModalOpen] = useState(false);
  const [jobSourceEmailModalOpen, setJobSourceEmailModalOpen] = useState(false);
  const [purchaseOrderNoModalOpen, setPurchaseOrderNoModalOpen] = useState(false);
  const [purchaseOrderNo, setPurchaseOrderNo] = useState('');
  const [selectedUpload, setSelectedUpload] = useState(null);
  const [jobSourceEmail, setJobSourceEmail] = useState('');

  const jobRes = useQuery(jobQuery, {variables: {id: jobNo}, fetchPolicy: 'network-only'});
  const [mutateJob, {loading}] = useMutation(jobMutation);
  const [mutateJobAddress, jaRes] = useMutation(jobAddressMutation);
  const [mutateJobClient, jcRes] = useMutation(jobClientMutation);
  const [mutateJobNote, jnRes] = useMutation(jobNoteMutation);
  const [removeJobClient, rjcRes] = useMutation(removeJobClientMutation);
  const [mutateJobTypeBreakdown, jtbRes] = useMutation(upsertJobTypeMutation);
  const [sendInvoice, siRes] = useMutation(sendJobInvoiceMutation);
  const [sendDocumentGroup, dgRes] = useMutation(sendDocumentGroupMutation);
  const [generateInvoice, giRes] = useMutation(generateInvoiceMutation);
  const [updateJobClient, ujcRes] = useMutation(updateJobClientMutation);
  const [updateInvoice, uiRes] = useMutation(updateInvoiceMutation);
  const [getS3SignedUrl, s3Res] = useMutation(getS3SignedUrlMutation);
  const [createJobUpload, cjuRes] = useMutation(createJobUploadMutation);
  const [sendJobUpload, sjuRes] = useMutation(sendJobUploadMutation);

  const updateJob = useDebounce(mutateJob, 500);
  const upsertJobTypeBreakdown = mutateJobTypeBreakdown;

  const toggleSurveyAddressModal = () => {
    setSurveyAddressModalOpen(!surveyAddressModalOpen);
  };

  const toggleBillingAddressModal = () => {
    setBillingAddressModalOpen(!billingAddressModalOpen);
  };

  const toggleClientModal = () => {
    setClientModalOpen(!clientModalOpen);
  };

  const toggleNoteModal = () => {
    setNotesModalOpen(!notesModalOpen);
  };

  const toggleRemoveJobClientConfirmModal = () => {
    setRemoveJobClientConfirmModalOpen(!removeJobClientConfirmModalOpen);
  };
  
  if(jobRes.loading) {
    return <Loading/>
  }

  const {job} = jobRes.data;
  const quoteDate = job.quote_date ? moment(job.quote_date).utc().format('YYYY-MM-DD') : ''
  const jobDate = job.job_date ? moment(job.job_date).utc().format('YYYY-MM-DD') : ''
  const jobTime = job.job_date ? moment(job.job_date).utc().format('HH:mm') : ''

  return (
    <div className="d-flex flex-column p-2">
      <h1 className="pt-2">{job.job_no}</h1>
      <Row form>
        <Col md={5}>
          <Row form>
            <Col>
              <Card>
                <CardHeader>Survey Details</CardHeader>
                <CardBody>
                  <AvForm>
                    <Row form>
                      <Col>
                        <AvLookupSelectField
                          label="Status"
                          name="status"
                          lookup="job_statuses"
                          required
                          value={job.job_status.id}
                          onChange={(job_status_id) => updateJob({variables: {data: {id: job.id, job_status_id}}})}
                        />
                      </Col>
                      <Col>
                        <JobSourceField
                          label="Source"
                          name="source"
                          value={job?.job_source?.id}
                          onChange={(job_source_id, {invoicing_email, purchase_order_no}) => {

                            if (invoicing_email) {
                              setJobSourceEmail(invoicing_email);
                              setJobSourceEmailModalOpen(true);
                            }

                            if(purchase_order_no) {
                              setPurchaseOrderNo(purchase_order_no);
                              setPurchaseOrderNoModalOpen(true);
                            }

                            updateJob({
                              variables: {
                                data: {
                                  id: job.id, 
                                  job_source_id,
                                },
                              },
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col>
                        <AvField
                          label="Invoicing Email"
                          name="invoicing_email"
                          value={job.invoicing_email}
                          bsSize="sm"
                          onChange={(evt) => updateJob({variables: {data: {id: job.id, invoicing_email: evt.target.value}}})}
                        />
                      </Col>
                      <Col>
                        <AvField
                          label="Quest Ref"
                          name="quest_ref"
                          value={job.quest_ref}
                          bsSize="sm"
                          onChange={(evt) => updateJob({variables: {data: {id: job.id, quest_ref: evt.target.value}}})}
                        />
                      </Col>
                      <Col>
                        <AvField
                          label="Purchase Order No"
                          name="purchase_order_no"
                          value={job.purchase_order_no}
                          bsSize="sm"
                          onChange={(evt) => updateJob({variables: {data: {id: job.id, purchase_order_no: evt.target.value}}})}
                        />
                      </Col>
                    </Row>
                    
                    <Row form>
                      <Col>
                        <AvField
                          label="Quote Date"
                          name="quote_date"
                          value={quoteDate}
                          bsSize="sm"
                          type="date"
                          onChange={(evt) => updateJob({variables: {data: {id: job.id, quote_date: evt.target.value}}})}
                        />
                      </Col>
                      <Col>
                          <Row form>
                            <Col>
                              <AvField
                                label="Survey Date"
                                name="job_date"
                                value={jobDate}
                                bsSize="sm"
                                type="date"
                                onChange={(evt) => updateJob({variables: {data: {id: job.id, job_date: `${evt.target.value} ${jobTime}`.trim()}}})}
                              />
                            </Col>
                            <Col>
                              <AvField
                                disabled={!jobDate}
                                label="Survey Time"
                                name="job_time"
                                value={jobTime}
                                bsSize="sm"
                                type="time"
                                onChange={(evt) => updateJob({variables: {data: {id: job.id, job_date: `${jobDate} ${evt.target.value}`}}})}
                              />
                            </Col>
                          </Row>
                      </Col>
                    </Row>
                    <Row form>
                      <Col>
                        <AvLookupSelectField
                          label="Property Type"
                          name="property_type"
                          lookup="property_types"
                          value={job?.property_type?.id}
                          onChange={(property_type_id) => updateJob({variables: {data: {id: job.id, property_type_id}}})}
                        />
                      </Col>
                      <Col>
                        <AvLookupSelectField
                          label="Property Condition"
                          name="property_condition"
                          lookup="property_conditions"
                          value={job?.property_condition?.id}
                          onChange={(property_condition_id) => updateJob({variables: {data: {id: job.id, property_condition_id}}})}
                        />
                      </Col>
                      <Col>
                        <AvLookupSelectField
                          label="Property Age"
                          name="property_age"
                          lookup="property_ages"
                          value={job?.property_age?.id}
                          onChange={(property_age_id) => updateJob({variables: {data: {id: job.id, property_age_id}}})}
                        />
                      </Col>
                    </Row>
                    
                    <Row form>
                      <Col>
                        <AvField
                          label="Number of Bedrooms"
                          name="no_of_beds"
                          value={job.number_of_beds}
                          bsSize="sm"
                          type="number"
                          onChange={(evt) => updateJob({variables: {data: {id: job.id, number_of_beds: evt.target.value}}})}
                        />
                      </Col>
                      <Col>
                        <AvField
                          label="Purchase Price"
                          name="purchase_price"
                          value={job.purchase_price}
                          bsSize="sm"
                          type="number"
                          onChange={(evt) => updateJob({variables: {data: {id: job.id, purchase_price: evt.target.value}}})}
                        />
                      </Col>
                    </Row>
                    
                    <FormGroup>
                      <Label>Specific Requirements</Label>
                      <Textarea
                        className="form-control form-control-sm"
                        value={job.specific_requirements}
                        onChange={(evt) => updateJob({variables: {data: {id: job.id, specific_requirements: evt.target.value}}})}
                      />
                    </FormGroup>

                    <AvField
                      label="Storage Ref"
                      name="storage_reference"
                      value={job.storage_reference}
                      bsSize="sm"
                      onChange={(evt) => updateJob({variables: {data: {id: job.id, storage_reference: evt.target.value}}})}
                    />

                    <AvLookupSelectField
                      label="Surveyor Assigned"
                      name="assigned_user"
                      lookup="users"
                      value={job?.assigned_user?.id}
                      onChange={(assigned_user_id) => updateJob({variables: {data: {id: job.id, assigned_user_id}}})}
                    />

                    <Row form>
                      <Col>
                        <AvField
                          label="Terms of Engagement Received"
                          type="checkbox"
                          name="terms_of_engagement"
                          value={job.terms_received}
                          onChange={(evt) => updateJob({variables: {data: {id: job.id, terms_received: evt.target.checked}}})}
                        />
                      </Col>
                      <Col>
                        <AvField
                          label="Conflict Checked"
                          type="checkbox"
                          name="conflict_checked"
                          value={job.conflict_checked}
                          onChange={(evt) => updateJob({variables: {data: {id: job.id, conflict_checked: evt.target.checked}}})}
                        />
                      </Col>
                    </Row>
                    
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row form>
            <Col>
              <Card className="mt-3">
                <CardHeader>Survey Property Address</CardHeader>
                <CardBody>
                  <PostcodeSearch
                    target="paTarget"
                    placeholder="Search Postcode..."
                    onSelect={({abode_name_no, street, city, county, postcode, latitude, longitude}) => mutateJobAddress({
                      variables: {
                        jobId: jobNo,
                        addressTypeId: 1,
                        address: {
                          abode_name_no,
                          street,
                          city,
                          county,
                          postcode,
                          latitude,
                          longitude,
                        }
                      },
                      optimisticResponse: {
                        __typename: 'Mutation',
                        upsertJobAddress: {
                          __typename: 'JobAddress',
                          job_address_type: {
                            __typename: 'JobAddressType',
                            id: '1',
                            description: ''
                          },
                          address: {
                            __typename: 'Address',
                            id: 'op',
                            abode_name_no,
                            street,
                            city,
                            county,
                            postcode,
                          }
                        }
                      },
                      update: (proxy, { data: { upsertJobAddress } }) => {
                        // Read the data from our cache for this query.
                        const data = proxy.readQuery({ query: jobQuery, variables: {id: jobNo} });
                        console.log('DATA', data, upsertJobAddress);
                        // Write our data back to the cache with the new comment in it
                        proxy.writeQuery({
                          query: jobQuery,
                          variables: {id: jobNo},
                          data: {
                            job: {
                              ...data.job,
                              job_addresses: [...data.job.job_addresses.filter(({job_address_type}) => job_address_type.id == 2), upsertJobAddress]
                            }
                          }});
                      }
                    })}
                  />
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <Button color="link" size="sm" onClick={() => {
                      setSurveyAddress(defaultAddress);
                      setSurveyAddressModalOpen(true);
                    }}>
                      Add Address Manually
                    </Button>
                  </div>
                  <div>
                    {job?.job_addresses &&
                      job.job_addresses
                        .filter(({job_address_type}) => job_address_type.id == 1)
                        .map(({address}) => (
                          <div className="d-flex flex-row justify-content-between border-bottom pb-2 pt-2">
                            <div className="d-flex flex-column">
                              <small>{address.abode_name_no}</small>
                              <small>{address.street}</small>
                              <small>{address.city}</small>
                              <small>{address.county}</small>
                              <small>{address.postcode}</small>
                            </div>
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                              <Button
                                size="sm"
                                color="link"
                                onClick={() => {
                                  setSurveyAddressModalOpen(true);
                                  setSurveyAddress(address);
                                }}
                              >
                                <FontAwesomeIcon
                                  color="#007bff"
                                  icon="pen"
                                />
                              </Button>
                            </div>
                          </div>
                        ))
                    }
                  </div>
                  
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="mt-3">
                <CardHeader>Billing Address</CardHeader>
                <CardBody>
                  <PostcodeSearch
                    target="baTarget"
                    placeholder="Search Postcode..."
                    onSelect={({abode_name_no, street, city, county, postcode, latitude, longitude}) => mutateJobAddress({
                      variables: {
                        jobId: jobNo,
                        addressTypeId: 2,
                        address: {
                          abode_name_no,
                          street,
                          city,
                          county,
                          postcode,
                          latitude,
                          longitude,
                        }
                      },
                      optimisticResponse: {
                        __typename: 'Mutation',
                        upsertJobAddress: {
                          __typename: 'JobAddress',
                          job_address_type: {
                            __typename: 'JobAddressType',
                            id: '2',
                            description: ''
                          },
                          address: {
                            __typename: 'Address',
                            id: 'op2',
                            abode_name_no,
                            street,
                            city,
                            county,
                            postcode,
                          }
                        }
                      },
                      update: (proxy, { data: { upsertJobAddress } }) => {
                        // Read the data from our cache for this query.
                        const data = proxy.readQuery({ query: jobQuery, variables: {id: jobNo} });
                        console.log('DATA', data, upsertJobAddress);
                        // Write our data back to the cache with the new comment in it
                        proxy.writeQuery({
                          query: jobQuery,
                          variables: {id: jobNo},
                          data: {
                            job: {
                              ...data.job,
                              job_addresses: [...data.job.job_addresses.filter(({job_address_type}) => job_address_type.id == 1), upsertJobAddress]
                            }
                          }});
                      }
                    })}
                  />

                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <Button color="link" size="sm" onClick={() => {
                      setBillingAddress(defaultAddress);
                      setBillingAddressModalOpen(true)
                    }}>
                      Add Address Manually
                    </Button>
                  </div>

                  <div>
                    {job?.job_addresses &&
                      job.job_addresses
                        .filter(({job_address_type}) => job_address_type.id == 2)
                        .map(({address}) => (
                          <div className="d-flex flex-row justify-content-between border-bottom pb-2 pt-2">
                            <div className="d-flex flex-column">
                              <small>{address.abode_name_no}</small>
                              <small>{address.street}</small>
                              <small>{address.city}</small>
                              <small>{address.county}</small>
                              <small>{address.postcode}</small>
                            </div>
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                              <Button
                                size="sm"
                                color="link"
                                onClick={() => {
                                  setBillingAddressModalOpen(true)
                                  setBillingAddress(address);
                                }}
                              >
                                <FontAwesomeIcon
                                  color="#007bff"
                                  icon="pen"
                                />
                              </Button>
                            </div>
                          </div>
                        ))
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        
        </Col>
        <Col md={5}>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    Clients
                     <Button size="sm" color="primary" onClick={() => setClientModalOpen(true)}>
                      <FontAwesomeIcon 
                        color="#fff" 
                        icon="plus"
                      />
                     </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  
                    {job.clients.length === 0
                    ? (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <h5>No Clients</h5>
                      </div>
                    )
                    : <JobClientList
                        data={job.clients}
                        onRemove={(id) => {
                          setRemoveJobClientID(id);
                          setRemoveJobClientConfirmModalOpen(true);
                        }}
                        onEdit={(client) => {
                          console.log(client);
                          setClientEdit(client)
                          setClientEditModalOpen(true);
                        }}
                    />
                    }
                  
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mt-3">
                <CardHeader>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    Survey Types
                     <div className="d-flex flex-column">
                      <small>
                        Net: {formater.formatCurrency(job.job_breakdown.reduce((carry, { net }) => carry + net, 0))} / Gross: {formater.formatCurrency(job.job_breakdown.reduce((carry, {gross}) => carry + gross, 0))}
                      </small>
                     </div>
                     
                  </div>
                </CardHeader>
                <CardBody>
                  <JobTypesInput
                    values={job.job_breakdown}
                    onChange={({ job_type_id, net, gross, description }) => {
                      upsertJobTypeBreakdown({
                        variables: {
                          data: {
                            job_id: jobNo,
                            job_type_id,
                            net,
                            gross,
                            description,
                          }
                        },
                        optimisticResponse: {
                          __typename: 'Mutation',
                          upsertJobTypeBreakdown: {
                            __typename: 'JobJobType',
                            description,
                            job_type: {
                              __typename: 'JobType',
                              id: job_type_id,
                              description: ''
                            },
                            net: parseFloat(net),
                            gross: parseFloat(gross),
                          }
                        },
                        update: (proxy, { data: { upsertJobTypeBreakdown } }) => {
                          // Read the data from our cache for this query.
                          const data = proxy.readQuery({ query: jobQuery, variables: {id: jobNo} });
                          console.log('DATA', data, upsertJobTypeBreakdown);
                          // Write our data back to the cache with the new comment in it
                          proxy.writeQuery({
                            query: jobQuery,
                            variables: {id: jobNo},
                            data: {
                              job: {
                                ...data.job,
                                job_breakdown: [
                                  ...data.job.job_breakdown.filter(({ job_type }) => job_type.id != upsertJobTypeBreakdown.job_type.id),
                                  upsertJobTypeBreakdown,
                                ]
                              }
                            }});
                        }
                      })
                    }}
                  />

                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value={job.use_single_invoice_value}
                        defaultChecked={job.use_single_invoice_value}
                        onChange={(evt) => mutateJob({
                          variables: {
                            data: {
                              id: jobNo,
                              use_single_invoice_value: evt.target.checked
                            }
                          },
                          optimisticResponse: {
                            __typename: 'Mutation',
                            updateJob: {
                              __typename: 'Job',
                              ...job,
                              use_single_invoice_value: evt.target.checked
                            }
                          },
                        })}
                      />{' '}
                      Use Single Invoice Amount
                    </Label>
                  </FormGroup>

                  {job.use_single_invoice_value && <div class="card card-body bg-light mt-3">
                    <Row form>
                      <Col>
                        <FormGroup>
                          <Label>(Net)</Label>
                          <InputGroup size="sm">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>£</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              size="sm"
                              type="number"
                              value={job.single_invoice_value_net}
                              onChange={(evt) => {
                                const net = evt.target.value;
                                const gross = (evt.target.value) ? (evt.target.value * 1.2).toFixed(2) : ''
                                mutateJob({
                                  variables: {
                                    data: {
                                      id: jobNo,
                                      single_invoice_value_net: net,
                                      single_invoice_value_gross: gross,
                                    }
                                  },
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    updateJob: {
                                      __typename: 'Job',
                                      ...job,
                                      single_invoice_value_net: net,
                                      single_invoice_value_gross: gross,
                                    }
                                  },
                                })
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>(Gross)</Label>
                          <InputGroup size="sm">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>£</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              size="sm"
                              type="number"
                              value={job.single_invoice_value_gross}
                              onChange={(evt) => {
                                const gross = evt.target.value;
                                const net = (evt.target.value) ? ((evt.target.value/120)*100).toFixed(2) : ''
                                mutateJob({
                                  variables: {
                                    data: {
                                      id: jobNo,
                                      single_invoice_value_net: net,
                                      single_invoice_value_gross: gross,
                                    }
                                  },
                                  optimisticResponse: {
                                    __typename: 'Mutation',
                                    updateJob: {
                                      __typename: 'Job',
                                      ...job,
                                      single_invoice_value_net: net,
                                      single_invoice_value_gross: gross,
                                    }
                                  },
                                })
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mt-3">
                <CardHeader>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    Notes
                     <Button size="sm" color="primary" onClick={() => setNotesModalOpen(true)}>
                      <FontAwesomeIcon 
                        color="#fff" 
                        icon="plus"
                      />
                     </Button>
                  </div>
                </CardHeader>
                
                <CardBody>
                  {job.notes.length === 0
                    ? (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <h5>No Notes</h5>
                      </div>
                    )
                      : <JobNotes notes={job.notes} />
                    }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={2}>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    Invoices
                    <Button size="sm" color="primary" onClick={() => setConfirmGenerateInvoiceModalOpen(true)}>
                      <FontAwesomeIcon 
                        color="#fff" 
                        icon="plus"
                      />
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  
                    {job.invoices.length === 0
                      ? (<div className="d-flex flex-column justify-content-center align-items-center">
                          <h5>No Invoices</h5>
                        </div>)
                        : (<InvoiceList
                            invoices={job.invoices}
                            onSend={(id) => {
                              setSendInvoiceID(id);
                              setConfirmSendInvoiceModalOpen(true);
                            }}
                            onSelect={(invoice) => {
                              setEditInvoice(invoice);
                              setEditInvoiceModalOpen(true);
                            }}
                          />)
                    }
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mt-3">
                <CardHeader>Email Documents</CardHeader>
                <CardBody>
                  <CompanyDocumentsList
                    emails={job.standard_document_emails}
                    onSend={(doc) => {
                      setSendDocument(doc);
                      setConfirmSendDocumentModalOpen(true);
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mt-3">
                <CardHeader>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    Uploads
                    <input
                      type="file"
                      style={{display: 'none'}}
                      ref={uploadInput}
                      onChange={async (evt) => {
                        setUploading(true);
                        try {
                          const [file] = evt.target.files;
                          const {data} = await getS3SignedUrl({
                            variables: {
                              name: file.name,
                              type: file.type,
                            }
                          });

                          const options = {
                            headers: {
                              'Content-Type': file.type,
                            },
                          };

                          console.log(data);

                          await axios.put(
                            data.getS3SignedUrl,
                            file,
                            options,
                          );

                          await createJobUpload({
                            variables: {
                              jobId: jobNo,
                              upload: {
                                name: file.name,
                                file_path: file.name,
                              },
                            },
                            update: (proxy, { data: { createJobUpload } }) => {
                              // Read the data from our cache for this query.
                              const data = proxy.readQuery({ query: jobQuery, variables: { id: jobNo } });
                              // Write our data back to the cache with the new comment in it
                              proxy.writeQuery({
                                query: jobQuery,
                                variables: { id: jobNo },
                                data: {
                                  job: {
                                    ...data.job,
                                    uploads: [
                                      {...createJobUpload.upload},
                                      ...data.job.uploads,
                                    ]
                                  }
                                }
                              });
                            }
                          });
                
                          
                        } catch (err) {
                          alert(err.message);
                        }
                        setUploading(false);
                      }}
                    />
                    <Button disabled={uploading} size="sm" color="primary" onClick={() => uploadInput.current.click()}>
                      <FontAwesomeIcon 
                        color="#fff" 
                        icon={uploading ? 'spinner' : 'plus'}
                        spin={uploading}
                      />
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  {
                    job.uploads.length === 0
                    ? (<div className="d-flex flex-column justify-content-center align-items-center">
                    <h5>No Uploads</h5>
                  </div>)
                  : (
                  <UploadsList
                    uploads={job.uploads}
                    onSend={(upload) => {
                      setSelectedUpload(upload);
                      setSendUploadModalOpen(true);
                    }}
                  />
                  )
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <AddressInputModal
        title="Enter Survey Property Address"
        isOpen={surveyAddressModalOpen}
        toggle={toggleSurveyAddressModal}
        onChange={({ key, value }) => {
          setSurveyAddress({
            ...surveyAddress,
            [key]: value,
          })
        }}
        {...surveyAddress}
        onSubmit={async (address) => {
          try {
            await mutateJobAddress({
              variables: {
                jobId: jobNo,
                addressTypeId: 1,
                address
              },
              optimisticResponse: {
                __typename: 'Mutation',
                upsertJobAddress: {
                  __typename: 'JobAddress',
                  job_address_type: {
                    __typename: 'JobAddressType',
                    id: '1',
                    description: ''
                  },
                  address: {
                    __typename: 'Address',
                    id: 'op1',
                    ...address,
                  }
                }
              },
              update: (proxy, { data: { upsertJobAddress } }) => {
                // Read the data from our cache for this query.
                const data = proxy.readQuery({ query: jobQuery, variables: { id: jobNo } });
                console.log('DATA', data, upsertJobAddress);
                // Write our data back to the cache with the new comment in it
                proxy.writeQuery({
                  query: jobQuery,
                  variables: { id: jobNo },
                  data: {
                    job: {
                      ...data.job,
                      job_addresses: [...data.job.job_addresses.filter(({ job_address_type }) => job_address_type.id == 2), upsertJobAddress]
                    }
                  }
                });
              }

            });
            setSurveyAddressModalOpen(false);
            setSurveyAddress(defaultAddress);
          } catch (err) {
            alert(err.message);
          }
        }}
      />
      <AddressInputModal
        title="Enter Billing Address"
        isOpen={billingAddressModalOpen}
        toggle={toggleBillingAddressModal}
        {...billingAddress}
        onChange={({ key, value }) => {
          setBillingAddress({
            ...billingAddress,
            [key]: value,
          })
        }}
        onSubmit={async (address) => {
          try {
            await mutateJobAddress({
              variables: {
                jobId: jobNo,
                addressTypeId: 2,
                address
              },
              optimisticResponse: {
                __typename: 'Mutation',
                upsertJobAddress: {
                  __typename: 'JobAddress',
                  job_address_type: {
                    __typename: 'JobAddressType',
                    id: '2',
                    description: ''
                  },
                  address: {
                    __typename: 'Address',
                    id: 'op1',
                    ...address,
                  }
                }
              },
              update: (proxy, { data: { upsertJobAddress } }) => {
                // Read the data from our cache for this query.
                const data = proxy.readQuery({ query: jobQuery, variables: { id: jobNo } });
                console.log('DATA', data, upsertJobAddress);
                // Write our data back to the cache with the new comment in it
                proxy.writeQuery({
                  query: jobQuery,
                  variables: { id: jobNo },
                  data: {
                    job: {
                      ...data.job,
                      job_addresses: [...data.job.job_addresses.filter(({ job_address_type }) => job_address_type.id == 1), upsertJobAddress]
                    }
                  }
                });
              }

            });
            setBillingAddressModalOpen(false);
            setBillingAddress(defaultAddress);
          } catch (err) {
            alert(err.message);
          }
        }}
      />
      <JobClientInputModal
        title="Add Client"
        isOpen={clientModalOpen}
        toggle={toggleClientModal}
        onClearUncommonDetails={() => {
          const newClient = JSON.parse(JSON.stringify(client));
          delete newClient.title_id;
          delete newClient.first_name;
          delete newClient.last_name;
          delete newClient.company_name;
          setClient(newClient);
        }}
        onChange={({key, value}) => {
          setClient({
            ...client,
            [key]: value,
          })
        }}
        onSubmit={async (client) => {
          const { title_id, first_name, last_name, company_name, email, phone } = client;
          const contact_methods = [];

          if (email) {
            contact_methods.push({
              contact_medium_id: 2,
              val: email,
            })
          }

          if (phone) {
            contact_methods.push({
              contact_medium_id: 1,
              val: phone,
            })
          }

          const clientDetails = JSON.parse(JSON.stringify(client));
          delete clientDetails.email;
          delete clientDetails.phone;

          try {
            await mutateJobClient({
              variables: {
                client: {
                  job_id: jobNo,
                  ...clientDetails,
                  title_id: title_id || null,
                  contact_methods,
                }
              },
              optimisticResponse: {
                __typename: 'Mutation',
                createJobClient: {
                  __typename: 'Client',
                  id: 'jc1',
                  title_id,
                  title: title_id ? {
                    __typename: 'Title',
                    id: title_id,
                    description: '',
                  } : null,
                  first_name,
                  last_name,
                  company_name,
                  contact_methods: contact_methods.map((cm, index) => ({
                    __typename: 'ContactMethod',
                    id: index,
                    contact_medium: {
                      __typename: 'ContactMedium',
                      id: cm.contact_medium_id,
                      description: '',
                    },
                    val: cm.val,
                  })),
                }
              },
              update: (proxy, { data: { createJobClient } }) => {
                // Read the data from our cache for this query.
                const data = proxy.readQuery({ query: jobQuery, variables: { id: jobNo } });
                console.log('DATA', data, createJobClient);
                // Write our data back to the cache with the new comment in it
                proxy.writeQuery({
                  query: jobQuery,
                  variables: { id: jobNo },
                  data: {
                    job: {
                      ...data.job,
                      clients: [createJobClient, ...data.job.clients]
                    }
                  }
                });
              }
            });

            setClientModalOpen(false);
            setClient(defaultClient);
          } catch (err) {
            alert(err.message);
          }
        }}
        {...client}
      />
      <ClientInputModal
        isOpen={clientEditModalOpen}
        toggle={() => {
          setClientEditModalOpen(!clientEditModalOpen);
          setClientEdit(null);
        }}
        id={clientEdit?.id}
        title="Edit Client"
        title_id={clientEdit?.title_id}
        first_name={clientEdit?.first_name}
        last_name={clientEdit?.last_name}
        contact_methods={clientEdit?.contact_methods}
        is_individual={clientEdit?.is_individual}
        company_name={clientEdit?.company_name}
        saving={ujcRes.loading}
        onChange={({key, value}) => {
          setClientEdit({
            ...clientEdit,
            [key]: value,
          })
        }}
        onSubmit={async ({
          id,
          title_id,
          first_name,
          last_name,
          company_name,
          contact_methods,
        }) => {
          try {
            await updateJobClient({
              variables: {
                client: {
                  id,
                  title_id: title_id || null,
                  first_name,
                  last_name,
                  company_name,
                  contact_methods: contact_methods.map((cm) => ({
                    id: cm.id,
                    contact_medium_id: cm.contact_medium.id,
                    val: cm.val,
                  })),
                }
              }
            });
            setClientEditModalOpen(!clientEditModalOpen);
            setClientEdit(null);
          } catch (err) {
            alert(err.message);
          }
          
        }}
      />
      <NoteInputModal
        title="Add Note"
        isOpen={notesModalOpen}
        toggle={toggleNoteModal}
        note={note}
        onChange={(note) => {
          setNote(note);
        }}
        onSubmit={async ({ note }) => {
          try {
            await mutateJobNote({
              variables: {
                data: {
                  job_id: jobNo,
                  note,
                }
              },
              optimisticResponse: {
                __typename: 'Mutation',
                upsertJobNote: {
                  __typename: 'JobNote',
                  id: 'jn1',
                  user: {
                    __typename: 'User',
                    id: 'user',
                    forename: '',
                    surname: ''
                  },
                  note,
                  created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                }
              },
              update: (proxy, { data: { upsertJobNote } }) => {
                // Read the data from our cache for this query.
                const data = proxy.readQuery({ query: jobQuery, variables: { id: jobNo } });
                console.log('DATA', data, upsertJobNote);
                // Write our data back to the cache with the new comment in it
                proxy.writeQuery({
                  query: jobQuery,
                  variables: { id: jobNo },
                  data: {
                    job: {
                      ...data.job,
                      notes: [upsertJobNote, ...data.job.notes]
                    }
                  }
                });
              }
            })
            setNotesModalOpen(false);
            setNote('');
          } catch (err) {
            alert(err.message);
          }
        }}
      />

      <ConfirmModal
        isOpen={removeJobClientConfirmModalOpen}
        toggle={toggleRemoveJobClientConfirmModal}
        title="Confirm"
        message="Are you sure you want to remove the client?"
        onConfirm={async () => {
          try {
            await removeJobClient({
              variables: {
                clientId: removeJobClientID,
                jobId: jobNo,
              },
              optimisticResponse: {
                __typename: 'Mutation',
                removeJobClient: true
              },
              update: (proxy, { data: { removeJobClient } }) => {
                // Read the data from our cache for this query.
                const data = proxy.readQuery({ query: jobQuery, variables: { id: jobNo } });
                console.log('DATA', data, removeJobClient);
                // Write our data back to the cache with the new comment in it
                proxy.writeQuery({
                  query: jobQuery,
                  variables: { id: jobNo },
                  data: {
                    job: {
                      ...data.job,
                      clients: [...data.job.clients.filter(({id}) => id !== removeJobClientID)]
                    }
                  }
                });
              }
            });

            setRemoveJobClientID(null);
            setRemoveJobClientConfirmModalOpen(false);
          } catch (err) {
            alert(err.message);
          }
          
        }}
        saving={rjcRes.loading}
      />
      <ConfirmModal
        isOpen={confirmSendInvoiceModalOpen}
        toggle={() => setConfirmSendInvoiceModalOpen(!confirmSendInvoiceModalOpen)}
        title="Confirm Send Invoice"
        message={`Are you sure you want to send the invoice to ${job.invoicing_email}`}
        saving={siRes.loading}
        onConfirm={async () => {
          try{
            await sendInvoice({
              variables: {
                invoiceId: sendInvoiceID,
                emails: [job.invoicing_email],
              },
              update: (proxy, { data: { sendJobInvoice } }) => {
                // Read the data from our cache for this query.
                const data = proxy.readQuery({ query: jobQuery, variables: { id: jobNo } });
                // Write our data back to the cache with the new comment in it
                const index = data.job.invoices.findIndex(({id}) => id === sendJobInvoice.invoice.id);
                proxy.writeQuery({
                  query: jobQuery,
                  variables: { id: jobNo },
                  data: {
                    job: {
                      ...data.job,
                      invoices: [
                        ...data.job.invoices.slice(0, index),
                        {
                          ...data.job.invoices[index],
                          emails: [
                            {...sendJobInvoice.email},
                            ...data.job.invoices[index].emails,
                          ]
                        },
                        ...data.job.invoices.slice(index+1),
                      ]
                    }
                  }
                });
              }
            })

            setSendInvoiceID(null);
            setConfirmSendInvoiceModalOpen(false);
          } catch (err) {
            alert(err.message);
          }
        }}
      />
      
      <CompanyDocumentEmailModal
        title="Send Email Document"
        message="Who would you like to send the document(s) to?"
        isOpen={confirmSendDocumentModalOpen}
        toggle={() => setConfirmSendDocumentModalOpen(!confirmSendDocumentModalOpen)}
        sending={dgRes.loading}
        clients={job.clients}
        invoicingEmail={job.invoicing_email}
        onSend={async (emails) => {
          try {
            await sendDocumentGroup({
              variables: {
                id: sendDocument.id,
                jobId: jobNo,
                emails,
              },
              update: (proxy, { data: { sendStandardDocumentGroup } }) => {
                // Read the data from our cache for this query.
                const data = proxy.readQuery({ query: jobQuery, variables: { id: jobNo } });
                // Write our data back to the cache with the new comment in it

                proxy.writeQuery({
                  query: jobQuery,
                  variables: { id: jobNo },
                  data: {
                    job: {
                      ...data.job,
                      standard_document_emails: [
                        ...sendStandardDocumentGroup,
                        ...data.job.standard_document_emails,
                      ]
                    }
                  }
                });
              }
            })
            setSendDocument(null);
            setConfirmSendDocumentModalOpen(false);
          } catch (err) {
            alert(err.message);
          }
        }}
      />

      <CompanyDocumentEmailModal
        title="Send Uploaded File"
        message="Who would you like to send the file to?"
        isOpen={sendUploadModalOpen}
        toggle={() => setSendUploadModalOpen(!sendUploadModalOpen)}
        sending={sjuRes.loading}
        clients={job.clients}
        invoicingEmail={job.invoicing_email}
        onSend={async (emails) => {
          try {
            await sendJobUpload({
              variables: {
                uploadId: selectedUpload.id,
                jobId: jobNo,
                emailTemplateId: 'kwd-uploaded-document-email',
                emails,
              },
              update: (proxy, { data: { sendJobUpload } }) => {
                // Read the data from our cache for this query.
                const data = proxy.readQuery({ query: jobQuery, variables: { id: jobNo } });
                // Write our data back to the cache with the new comment in it

                const index = data.job.uploads.findIndex(({id}) => parseInt(id) === parseInt(sendJobUpload.upload_id));

                console.log(index, sendJobUpload, [
                  data.job.uploads.slice(0, index),
                  {
                    ...data.job.uploads[index],
                    job_emails: [
                      {...sendJobUpload.email},
                      ...data.job.uploads[index].job_emails,
                    ]
                  },
                  data.job.uploads.slice(index+1),
                ])


                proxy.writeQuery({
                  query: jobQuery,
                  variables: { id: jobNo },
                  data: {
                    job: {
                      ...data.job,
                      uploads: [
                        ...data.job.uploads.slice(0, index),
                        {
                          ...data.job.uploads[index],
                          job_emails: [
                            {...sendJobUpload.email},
                            ...data.job.uploads[index].job_emails,
                          ]
                        },
                        ...data.job.uploads.slice(index+1),
                      ]
                    }
                  }
                });
              }
            })
            setSelectedUpload(null);
            setSendUploadModalOpen(false);
          } catch (err) {
            alert(err.message);
          }
        }}
      />
      <ConfirmModal
        isOpen={confirmGenerateInvoiceModalOpen}
        toggle={() => setConfirmGenerateInvoiceModalOpen(!confirmGenerateInvoiceModalOpen)}
        title="Confirm Generate Invoice"
        message="Are you sure you want to generate an invoice for this job?"
        saving={giRes.loading}
        onConfirm={async () => {
          try {
            await generateInvoice({
              variables: {
                jobId: jobNo,
              },
              update: (proxy, { data: { generateJobInvoice } }) => {
                // Read the data from our cache for this query.
                const data = proxy.readQuery({ query: jobQuery, variables: { id: jobNo } });
                // Write our data back to the cache with the new comment in it

                proxy.writeQuery({
                  query: jobQuery,
                  variables: { id: jobNo },
                  data: {
                    job: {
                      ...data.job,
                      invoices: [
                        {...generateJobInvoice.invoice},
                        ...data.job.invoices,
                      ]
                    }
                  }
                });
              }
            })
            setConfirmGenerateInvoiceModalOpen(false);
          } catch (err) {
            console.log(err);
            alert(err.message);
          }
        }}
      />
      <InvoiceInputModal
        isOpen={editInvoiceModalOpen}
        toggle={() => setEditInvoiceModalOpen(!editInvoiceModalOpen)}
        {...editInvoice}
        title={`Edit Invoice: ${editInvoice?.invoice_no}`}
        onChange={({key, value}) => {
          setEditInvoice({
            ...editInvoice,
            [key]: value,
          });
        }}
        saving={uiRes.loading}
        onSubmit={async () => {
          try {
            await updateInvoice({
              variables: {
                data: {
                  id: editInvoice.id,
                  date_paid: editInvoice.date_paid,
                  active: editInvoice.active,
                },
              },
              update: (proxy, {data: {updateInvoice}}) => {
                // Read the data from our cache for this query.
                const data = proxy.readQuery({ query: jobQuery, variables: { id: jobNo } });
                // Write our data back to the cache with the new comment in it
                const index = data.job.invoices.findIndex(({id}) => updateInvoice.id === id);

                const newData = (!updateInvoice.active) ?
                  data.job.invoices.filter(({id}) => id !== updateInvoice.id) :
                  ([
                    ...data.job.invoices.slice(0, index),
                    {...updateInvoice},
                    ...data.job.invoices.slice(index+1),
                  ]);

                  proxy.writeQuery({
                    query: jobQuery,
                    variables: { id: jobNo },
                    data: {
                      job: {
                        ...data.job,
                        invoices: [
                          ...newData,
                        ]
                      }
                    }
                  });
              },
            });

            setEditInvoiceModalOpen(false);
            setEditInvoice(null);
          } catch (err) {
            alert(err.message);
          }
        }}
      />

      <YesNoModal
        isOpen={jobSourceEmailModalOpen}
        toggle={() => setJobSourceEmailModalOpen(!jobSourceEmailModalOpen)}
        title="Invoicing Email"
        message={`The selected job source has an invoicing email. Would you like to set the invoicing email to ${jobSourceEmail}`}
        onYes={async () => {
          try {
            await mutateJob({
              variables: {
                data: {
                  id: job.id,
                  invoicing_email: jobSourceEmail,
                },
              },
              optimisticResponse: {
                __typename: 'Mutation',
                updateJob: {
                  ...job,
                  invoicing_email: jobSourceEmail,
                }
              },
            });
          } catch (err) {
            alert(err.message);
          }
          setJobSourceEmail('');
          setJobSourceEmailModalOpen(false);
        }}
        onNo={() => {
          setJobSourceEmail('');
          setJobSourceEmailModalOpen(false);
        }}
      />

      <YesNoModal
        isOpen={purchaseOrderNoModalOpen}
        toggle={() => setPurchaseOrderNoModalOpen(!purchaseOrderNoModalOpen)}
        title="Purchase Order Number"
        message={`The selected job source has a purchase order number. Would you like to set the purchase order no to ${purchaseOrderNo}`}
        onYes={async () => {
          try {
            await mutateJob({
              variables: {
                data: {
                  id: job.id,
                  purchase_order_no: purchaseOrderNo,
                },
              },
              optimisticResponse: {
                __typename: 'Mutation',
                updateJob: {
                  ...job,
                  purchase_order_no: purchaseOrderNo,
                }
              },
            });
          } catch (err) {
            alert(err.message);
          }
          setPurchaseOrderNo('');
          setPurchaseOrderNoModalOpen(false);
        }}
        onNo={() => {
          setPurchaseOrderNo('');
          setPurchaseOrderNoModalOpen(false);
        }}
      />
    </div>
  )
}

export default Job;
