import {gql} from 'apollo-boost';

export default gql `
mutation ($invoiceId: Float!, $emails: [String]!){
  sendJobInvoice(invoiceId: $invoiceId, emails: $emails){
    email{
      id
      date_sent
      email
      status
    }
    invoice{
      id
      invoice_no
    }
  }
}	
`;
