import {gql} from 'apollo-boost'

export default gql`
mutation ($jobId: String!, $addressTypeId: Int!, $address: AddressInput!) {
  upsertJobAddress(jobId: $jobId, addressTypeId: $addressTypeId, address: $address) {
    job_address_type {
      id
      description
    }
    address {
      id
      abode_name_no
      street
      city
      county
      postcode
    }
  }
}
`