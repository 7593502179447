import React from 'react';
import {ApolloProvider} from '@apollo/react-hooks';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import {library} from '@fortawesome/fontawesome-svg-core';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';

import Login from './components/auth/Login';
import Payment from './components/payments/SCAPayment';
import PaymentSuccess from './components/payments/PaymentSuccess';
import AuthRoutes from './components/routes/AuthRoutes';
import client from './lib/connect';

library.add(far, fas);

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route path="/" exact component={Login}/>
          <Route path="/payments/:companyId/:invoiceId" exact component={Payment} />
          <Route path="/payments/:companyId/:invoiceId/success" exact component={PaymentSuccess} />
          <Route path="/" component={AuthRoutes}/>
        </Switch>
      </Router>
    </ApolloProvider>
  );
}

export default App;
