import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import CompanyDocumentList from './CompanyDocumentList';

import query from '../../queries/standardDocumentGroups';

const CompanyDocumentsListContainer = ({emails, onSend}) => {
  const {data, loading} = useQuery(query, {fetchPolicy: 'network-only'});

  if (loading) {
    return null;
  }

  return (
    <CompanyDocumentList
      data={data.standard_document_groups}
      emails={emails}
      onSend={onSend}
    />
  );
};

CompanyDocumentsListContainer.propTypes = {
  emails: PropTypes.array,
  onSend: PropTypes.func.isRequired,
};

export default CompanyDocumentsListContainer;
