import {gql} from 'apollo-boost';

export default gql `
mutation ($invoiceId: String!, $paymentIntentId: String!){
  setInvoicePaid(invoiceId: $invoiceId, paymentIntentId: $paymentIntentId){
    id
    invoice_no
    date_paid
    total_value_gross
    total_value_net
    total_vat
    job{
      invoicing_email
      job_types_summary
    }
  }
}
`;
