export const getIcon = (typeId) => {
  switch (parseInt(typeId)) {
    case 1:
      return {colour: '#000', icon: 'mobile-alt'};
    case 2:
      return {colour: '#000', icon: 'at'};
    case 3:
      return {colour: '#000', icon: 'phone'};
    case 4:
      return {colour: '#000', icon: 'fax'};
    default:
      return {colour: '#000', icon: 'tty'};
  }
};
