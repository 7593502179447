import React from 'react';
import PropTypes from 'prop-types';

import CompanyDocumentListItem from './CompanyDocumentListItem';

const CompanyDocumentsList = ({data, emails, onSend}) => {
  return (
    <div className="d-flex flex-column">
      {data && data.map((doc) => (
        <CompanyDocumentListItem
          key={doc.id}
          {...doc}
          emails={emails
              .filter(({standard_document_group_id}) => (
                standard_document_group_id == doc.id
              ))
              .map(({email}) => email)}
          onSend={onSend}
        />
      ))}
    </div>
  );
};

CompanyDocumentsList.propTypes = {
  data: PropTypes.array,
  emails: PropTypes.array,
  onSend: PropTypes.func.isRequired,
};

export default CompanyDocumentsList;
