import React, {useState} from 'react'
import {useLazyQuery} from '@apollo/react-hooks'
import {
  ListGroup,
  ListGroupItem,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Popover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import query from '../../queries/searchPostcode'

const PostcodeSearch = ({placeholder, target, onSelect}) => {

  const [postcode, setPostcode] = useState('')
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [search, {called, loading, data}] = useLazyQuery(query, {onCompleted: () => setPopoverOpen(true)})

  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div>
      <InputGroup id={target}>
        <Input
          type="text"
          size="sm"
          value={postcode}
          onChange={(evt) => setPostcode(evt.target.value)}
          placeholder={placeholder}
        />
        <InputGroupAddon addonType="append">
          <Button 
            color="secondary"
            size="sm"
            disabled={!postcode || (called && loading)}
            onClick={() => search({variables: {postcode}})}
          >
            <FontAwesomeIcon 
              color="#fff" 
              icon={(called && loading) ? 'spinner' : 'search'}
            />
          </Button>
        </InputGroupAddon>
      </InputGroup>
      <Popover
        style={{maxHeight: '400px', overflow: 'auto'}}
        target={target}
        placement="right"
        isOpen={data?.searchPostcode?.addresses && !loading && popoverOpen}
        toggle={toggle}
        trigger="legacy"
      >
        <ListGroup flush>
          {data?.searchPostcode?.addresses.map((address, index) => (
            <ListGroupItem tag="a" href="#" onClick={() => {
              onSelect(address);
              setPopoverOpen(false);
              setPostcode('');
            }}>{address.formatted_address}</ListGroupItem>
          ))}
        </ListGroup>
      </Popover>
        
    </div>
  )
}

export default PostcodeSearch