import React from 'react';
import PropTypes from 'prop-types';

import UploadsListItem from './UploadsListItem';

const UploadsList = ({uploads, onSend}) => {
  return (
    <div className="d-flex flex-column">
      {uploads.map((upload) => (
        <UploadsListItem key={upload.id} upload={upload} onSend={onSend} />
      ))}
    </div>
  );
};

UploadsList.propTypes = {
  uploads: PropTypes.array,
  onSend: PropTypes.func.isRequired,
};

export default UploadsList;
