import React from 'react';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import EmailList from '../emails/EmailList';

const CompanyDocumentListItem = ({id, description, onSend, emails}) => {
  return (
    <div className={`pt-2 pb-2 ${!emails.length && 'border-bottom'}`}>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <h6>{description}</h6>
        <div className="d-flex flex-row">
          <Button color="link" size="sm" onClick={() => {
            onSend({id, description});
          }}>
            <FontAwesomeIcon icon="paper-plane" />
          </Button>
        </div>
      </div>
      <EmailList emails={emails} />
    </div>
  );
};

CompanyDocumentListItem.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onSend: PropTypes.func.isRequired,
  emails: PropTypes.array,
};

export default CompanyDocumentListItem;
