import React, {useState} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {Link, withRouter} from 'react-router-dom';
import {useQuery} from '@apollo/react-hooks';

import query from '../../queries/me';
import Logo from '../../assets/images/logo-black.png';

const Navigation = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {data, loading} = useQuery(query, {fetchPolicy: 'network-only'});

  return (
    <Navbar color="light" light expand="md">
      <NavbarBrand tag={Link} to="/jobs">
        <img alt="Minute Surveyor CRM" src={Logo} className="nav-logo"/>
      </NavbarBrand>
      <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          <NavItem>
            <NavLink tag={Link} to="/jobs">Jobs</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/invoices">Invoices</NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
                        Reports
            </DropdownToggle>
            <DropdownMenu left="true">
              <DropdownItem tag={Link} to="/income_breakdown">
                            Income Breakdown
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
                        Admin
            </DropdownToggle>
            <DropdownMenu left="true">
              <DropdownItem header>
                            Company
              </DropdownItem>
              <DropdownItem tag={Link} to="/settings">
                            Settings
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>


        </Nav>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              {(!loading && data) ? `${data.me.forename} ${data.me.surname}`: ''}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => {
                sessionStorage.clear();
                props.history.replace('/');
              }}>
                            Log out
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default withRouter(Navigation)
;
