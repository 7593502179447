import {gql} from 'apollo-boost';

export default gql `
mutation ($id: Float!, $jobId: Float!, $emails: [String]!){
  sendStandardDocumentGroup(standardDocumentGroupId: $id, jobId: $jobId, emails: $emails){
    standard_document_group_id
    email{
      id
      date_sent
      email
      status
    }
  }
}
`;
