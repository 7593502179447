import React from 'react'
import {Input, Badge} from 'reactstrap'

import LookupSelect from '../components/global/LookupSelect'
import PaymentBadge from '../components/invoices/InvoicePaymentStatusBadge';
import currencyFormater from './currencyFormater'
import dateFormatter from './dateFormatter'


const getCsvData = (data, settings) => {

  const headers = [
    {
      label: 'Job No',
      key: 'job_no'
    },
    {
      label: 'Job Types',
      key: 'job_types'
    },
    {
      label: 'Clients',
      key: 'clients'
    },
    {
      label: 'Status',
      key: 'job_status_id'
    },
    {
      label: 'Source',
      key: 'job_source_id'
    },
    {
      label: 'Quote Date',
      key: 'quote_date'
    },
    {
      label: 'Job Date',
      key: 'job_date'
    },
    {
      label: 'Property Type',
      key: 'property_type_id'
    },
    {
      label: 'Purchase Price',
      key: 'purchase_price'
    },
    {
      label: 'Job Types',
      key: 'job_breakdown'
    },
    {
      label: 'Assigned User',
      key: 'assigned_user'
    },
    {
      label: 'Total Net',
      key: 'total_net'
    },
    {
      label: 'Total Gross',
      key: 'total_gross'
    }
  ]
    .reduce((carry, header) => {
      if(settings.some(({page_object_setting_id, active}) => page_object_setting_id === header.key && active)) {
        carry.push({
          ...header, 
          order_by: settings
            .find(({page_object_setting_id}) => page_object_setting_id === header.key)
            .order_by
        })
      }
      return carry
    }, [])
    .sort((a, b) => a.order_by - b.order_by)

  console.log(headers, settings)

  const formattedData = data.map(item => {
    const value = headers.reduce((carry, header) => {
      carry[header.key] = item[header.key]
      return carry
    }, {})
    return {
      ...value,
      job_types: item?.job_breakdown?.map(({job_type: {description}}) => description).join(', '),
      clients: item?.clients?.map(({is_individual, title, first_name, last_name, company_name}) => (is_individual ? `${(title ? title.description : '')} ${first_name} ${last_name}`.replace(' ', '') : `${company_name}`)).join(', '),
      job_status_id: item?.job_status?.description || '',
      job_source_id: item?.job_source?.description || '',
      property_type_id: item?.property_type?.description || '',
      assigned_user: `${(item?.assigned_user?.forename) || ''} ${(item?.assigned_user?.surname || '')}`,
      total_net: item?.job_breakdown?.reduce((carry, {net}) => carry + net, 0),
      total_gross: item?.job_breakdown?.reduce((carry, {gross}) => carry + gross, 0),
    }
  })

  return {
    headers,
    data: formattedData
  }
}

const getColumns = (filters, settings) => {

  if(!settings || settings.length === 0) return []

  return [
    {
      Header: 'Job No',
      id: 'job_no',
      accessor: 'job_no',
    },
    {
      Header: 'Job Types',
      id: 'job_types',
      accessor: 'job_breakdown',
      Cell: row => (
        <div className="d-flex flex-column">
          {row.value.map(({job_type, net, gross}) => 
            <span key={job_type.id}>{job_type.description}: {currencyFormater.formatCurrency(net)}</span>)}
        </div>
      )
    },
    {
      Header: 'Clients',
      id: 'clients',
      accessor: 'clients',
      Cell: row => (
        <div className="d-flex flex-column">
          {row.value.map(
            ({id, company_name, title, first_name, last_name, is_individual, contact_methods}) => 
              (
                <div className="d-flex flex-column">
                  <span key={id}>{(is_individual) ? `${title?.description || ''} ${first_name} ${last_name}` : company_name}</span>
                  {contact_methods.map(({val}, index) => <small key={index}>{val}</small>)}
                </div>
                

              )
          )}
        </div>
      )
    },
    {
      Header: 'Status',
      id: 'job_status_id',
      arrayFilter: true,
      accessor: 'job_status.description',
      Filter: ({ filter, onChange }) => (
        <LookupSelect 
          lookup="job_statuses" 
          onChange={onChange}
          value={filters?.job_status_id ? filters?.job_status_id[0] : ''}
        />
      )
    },
    {
      Header: 'Source',
      id: 'job_source_id',
      accessor: 'job_source.description',
      Filter: ({ filter, onChange }) => (
        <LookupSelect 
          lookup="job_sources" 
          onChange={onChange}
          value={filters?.job_source_id ? filters?.job_source_id[0] : ''}
        />
      )
    },
    {
      Header: 'Quote Date',
      id: 'quote_date',
      accessor: ({quote_date}) => quote_date ? dateFormatter.formatDate(quote_date) : '',
      Filter: ({filter, onChange}) => (
        <div className="d-flex flex-column">
          <Input 
            type="date" 
            value={filters?.quote_date?.start} 
            onChange={(evt) => 
                onChange({start: evt.target.value, end: filters?.quote_date?.end})}
          />
          <Input 
            type="date" 
            value={filters?.quote_date?.end} 
            onChange={(evt) => 
                onChange({end: evt.target.value, start: filters?.quote_date?.start})}
          />
        </div>
      ),
    },
    {
      Header: 'Job Date',
      id: 'job_date',
      accessor: ({job_date}) => job_date ? dateFormatter.formatDate(job_date) : '',
      Filter: ({filter, onChange}) => (
        <div className="d-flex flex-column">
          <Input 
            type="date" 
            value={filters?.job_date?.start} 
            onChange={(evt) => 
                onChange({start: evt.target.value, end: filters?.job_date?.end})}
          />
          <Input 
            type="date" 
            value={filters?.job_date?.end} 
            onChange={(evt) => 
                onChange({end: evt.target.value, start: filters?.job_date?.start})}
          />
        </div>
      ),
    },
    {
      Header: 'Property Address',
      id: 'property_address_summary',
      accessor: 'property_address_summary',
    },
    {
      Header: 'Property Type',
      id: 'property_type_id',
      accessor: 'property_type.description',
      Filter: ({ filter, onChange }) => (
        <LookupSelect 
          lookup="property_types" 
          onChange={onChange}
          value={filters?.property_type_id ? filters?.property_type_id[0] : ''}
        />
      )
    },
    {
      Header: 'Purchase Price',
      id: 'purchase_price',
      accessor: ({purchase_price}) => currencyFormater.formatCurrency(purchase_price),
      Filter: ({filter, onChange}) => (
        <div className="d-flex flex-column">
          <Input 
            type="number" 
            value={filters?.purchase_price?.start} 
            onChange={(evt) => 
                onChange({start: evt.target.value, end: filters?.purchase_price?.end})}
          />
          <Input 
            type="number" 
            value={filters?.purchase_price?.end} 
            onChange={(evt) => 
                onChange({end: evt.target.value, start: filters?.purchase_price?.start})}
          />
        </div>
      ),
    },
    {
      Header: 'Assigned To',
      id: 'assigned_user_id',
      accessor: ({assigned_user}) => assigned_user ? `${assigned_user.forename} ${assigned_user.surname}` : '',
      Filter: ({ filter, onChange }) => (
        <LookupSelect 
          lookup="users" 
          onChange={onChange}
          value={filters?.assigned_user_id ? filters?.assigned_user_id[0] : ''}
        />
      )
    },
    {
      Header: 'Total Net',
      id: 'total_net',
      accessor: ({total_net}) => currencyFormater.formatCurrency(total_net),
    },
    {
      Header: 'Total Gross',
      id: 'total_gross',
      accessor: ({total_gross}) => currencyFormater.formatCurrency(total_gross),
    },
    {
      Header: 'Invoices',
      id: 'invoices',
      accessor: 'invoices',
      Cell: row => (
        <div>
          {row.value.map(({invoice_no, date_paid}) => 
            <div className="d-flex flex-column" key={invoice_no}>
              <span>{invoice_no}</span>
              <PaymentBadge datePaid={date_paid} />
            </div>)}
        </div>
      ),
      Filter: ({filter, onChange}) => (
        <div className="d-flex flex-column">
          <Input 
            bsSize="sm"
            type="select" 
            value={filters?.invoices} 
            onChange={(evt) => onChange(evt.target.value)}
          >
            <option value="">Show All</option>
            <option value="no_invoice">No Invoice</option>
            <option value="has_invoice">Has Invoice</option>
          </Input>
        </div>
      )
    }
  ]
  .reduce((carry, column) => {
    if(settings.some(({page_object_setting_id, active}) => page_object_setting_id === column.id && active)) {
      carry.push({
        ...column, 
        order_by: settings
          .find(({page_object_setting_id}) => page_object_setting_id === column.id)
          .order_by
      })
    }
    return carry
  }, [])
  .sort((a, b) => a.order_by - b.order_by)

}

export default {
  getColumns,
  getCsvData,
}