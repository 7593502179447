import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const EmailListItem = ({email}) => {
  return (
    <div className="d-flex flex-column border-bottom pt-2 pb-2">
      <small><strong>To:</strong> {email.email}</small>
      <small><strong>Status:</strong> {email.status}</small>
      <small>
        <strong>Sent: </strong>
        {moment(email.date_sent).format('DD/MM/YYYY HH:mm')}
      </small>
    </div>
  );
};

EmailListItem.propTypes = {
  email: PropTypes.object.isRequired,
};

export default EmailListItem;
