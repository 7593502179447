import {
  gql,
} from 'apollo-boost';

export default gql `
mutation($client: ClientInput!) {
  createJobClient(client: $client) {
    id
    title_id
    title {
      id
      description
    }
    first_name
    last_name
    is_individual
    company_name
    contact_methods {
      id
      contact_medium {
        id
        description
      }
      val
    }
  }
}
`;