import React, {useState, useCallback} from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import debounce from 'lodash/debounce';

import Loading from '../global/Loading';
import CompanySettingListItem from './CompanySettingListItem';

import query from '../../queries/companySettings';
import mutation from '../../mutations/upsertCompanySetting';

function useDebounce(callback, delay) {
  const debouncedFn = useCallback(
      debounce((...args) => callback(...args), delay),
      [delay], // will recreate if delay changes
  );
  return debouncedFn;
}

const CompanySettings = () => {
  const {data, loading, error} = useQuery(query, {fetchPolicy: 'network-only'});
  const [showLoading, setShowLoading] = useState(false);
  const [upsertCompanySetting, res] = useMutation(mutation);
  const dbUpsertCompanySetting = useDebounce(upsertCompanySetting, 500);

  if (loading) return <Loading/>;

  return (
    <div className="d-flex flex-column pt-2">
      <div className="d-flex flex-row p-3 justify-content-between align-items-center">
        <div>
          <h1>Company Settings</h1>
          <p className="lead">
          Here you can set up your settings which can then be used in invoice and email templates.
          </p>
        </div>
      </div>

      {data.companySettings
          .sort((a, b) => a.setting.description.localeCompare(b.setting.description))
          .map((setting) => {
            return (
              <CompanySettingListItem
                key={setting.id}
                {...setting}
                onChange={async (newSetting) => {
                  await dbUpsertCompanySetting({
                    variables: {
                      data: newSetting,
                    },
                    optimisticResponse: {
                      __typename: 'Mutation',
                      upsertCompanySetting: {
                        __typename: 'CompanySetting',
                        ...setting,
                        value: newSetting.val,
                      },
                    },
                  });
                }}
              />
            );
          })}
    </div>
  );
};

export default CompanySettings;
