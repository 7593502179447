import React from 'react';
import {Modal, ModalBody, ModalHeader, ModalFooter, Button} from 'reactstrap';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';

const NoteInputModal = (props) => {
  const {
    note,
    isOpen,
    toggle,
    title,
    onChange,
    onSubmit,
  } = props;
  return (

    <Modal isOpen={isOpen} toggle={toggle}>
      <AvForm onValidSubmit={() => {
        onSubmit({
          note,
        });
      }}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <AvField
            style={{height: '250px'}}
            value={note}
            bsSize="sm"
            type="textarea"
            label="Note *"
            name="jobNote"
            required
            onChange={(evt) => {
              onChange(evt.target.value);
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button color="success" size="sm">Save</Button>
        </ModalFooter>
      </AvForm>
    </Modal>

  );
};

NoteInputModal.propTypes = {
  note: PropTypes.string,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default NoteInputModal;
