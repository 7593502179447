import React from 'react';
import {
  Route,
  Redirect,
  withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';

const AuthRoute = ({component: Component, ...rest}) => {
  const token = sessionStorage.getItem('token');

  if (!token) {
    return <Redirect to="/"/>;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Component {...props} />
      )}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default withRouter(AuthRoute);
