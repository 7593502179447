import React, {useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import PropTypes from 'prop-types';

import Loading from '../global/Loading';

import query from '../../queries/jobTypes';

const JobTypesInput = ({values, onChange}) => {
  const {data, loading} = useQuery(query, {fetchPolicy: 'network-only'});
  const jobTypes = (!loading && data) ? data.jobTypes : null;

  if (loading) return <Loading/>;

  return (
    <div>
      {jobTypes && jobTypes.map(({ id, description }) => {
        
        const matchedType = values.find(({job_type}) => job_type.id == id)

        return (
          <Row key={id}>
            <Col>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label>{description} (Net)</Label>
                    <InputGroup size="sm">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>£</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        size="sm"
                        type="number"
                        value={matchedType?.net}
                        onChange={(evt) => {
                          const net = evt.target.value;
                          const gross = (evt.target.value) ? (evt.target.value * 1.2).toFixed(2) : ''
                          onChange({
                            job_type_id: id,
                            net,
                            gross,
                            description: matchedType?.description,
                          })
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>(Gross)</Label>
                    <InputGroup size="sm">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>£</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        size="sm"
                        type="number"
                        value={matchedType?.gross}
                        onChange={(evt) => {
                          const gross = evt.target.value;
                          const net = (evt.target.value) ? ((evt.target.value/120)*100).toFixed(2) : ''
                          onChange({
                            job_type_id: id,
                            net,
                            gross,
                            description: matchedType?.description,
                          })
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              {(id == 7 && matchedType?.net) &&
                (<Row>
                  <Col>
                    <FormGroup>
                      <Label>Other Description</Label>
                      <Input
                        size="sm"
                        value={matchedType.description}
                        onChange={(evt) => {
                          onChange({
                            job_type_id: id,
                            net: matchedType?.net,
                            gross: matchedType?.gross,
                            description: evt.target.value,
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>)
              }
            </Col>
          </Row>
          
        );
      })}
    </div>
  );
};

JobTypesInput.propTypes = {
  values: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default JobTypesInput;
