import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupAddon, InputGroupText, Input} from 'reactstrap';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import AvLookupSelectField from '../global/AvLookupSelectField';
import ContactMediumDropdown from '../contact_methods/ContactMediumDropdown'

import {getIcon} from '../../lib/contactMediumHelper';

const ClientForm = ({
  id,
  title,
  title_id,
  first_name,
  last_name,
  is_individual,
  company_name,
  contact_methods,
  isOpen,
  toggle,
  onChange,
  onSubmit,
  saving,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <AvForm onValidSubmit={() => onSubmit({
        id,
        title_id,
        first_name,
        last_name,
        company_name,
        contact_methods,
      })}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          {is_individual ?
          <Row form>
            <Col xs={2}>
              <AvLookupSelectField
                name="title_id"
                value={title_id}
                bsSize="sm"
                lookup="titles"
                label="Title"
                onChange={(title_id) => {
                  onChange({key: 'title_id', value: title_id});
                }}
              />
            </Col>
            <Col>
              <AvField
                value={first_name}
                bsSize="sm"
                label="First Name *"
                name="first_name"
                required
                onChange={(evt) => {
                  onChange({key: 'first_name', value: evt.target.value});
                }}
              />
            </Col>
            <Col>
              <AvField
                value={last_name}
                bsSize="sm"
                label="Last Name *"
                name="last_name"
                required
                onChange={(evt) => {
                  onChange({key: 'last_name', value: evt.target.value});
                }}
              />
            </Col>
          </Row> :
          <Row form>
            <Col>
            <AvField
                value={company_name}
                bsSize="sm"
                label="Company Name *"
                name="company_name"
                required
                onChange={(evt) => {
                  onChange({key: 'company_name', value: evt.target.value});
                }}
              />
            </Col>
          </Row>
          }
          {contact_methods?.map((cm, index) => {
            const {icon} = getIcon(cm.contact_medium.id);
            return (
              <Row form key={cm.id} className="pb-3">
                <Col>
                  <InputGroup size="sm">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <div className="d-flex align-items-center justify-content-center" style={{width: '14px'}}>
                          <FontAwesomeIcon icon={icon} />
                        </div>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      size="sm"
                      type="text"
                      value={cm.val}
                      onChange={(evt) => {
                        const newContactMethods = [
                          ...contact_methods.slice(0, index),
                          {...cm, val: evt.target.value},
                          ...contact_methods.slice(index + 1),
                        ];
                        onChange({key: 'contact_methods', value: newContactMethods});
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
          )})}
          <ContactMediumDropdown
            onSelect={(id) => {
              const newCM = {
                val: '',
                contact_medium: {id},
              };

              const newContactMethods = [
                ...contact_methods,
                newCM,
              ];
              onChange({key: 'contact_methods', value: newContactMethods});
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            size="sm"
            disabled={saving}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

ClientForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  title_id: PropTypes.number.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  contact_methods: PropTypes.array,
  id: PropTypes.number,
  saving: PropTypes.bool,
};

export default ClientForm;
