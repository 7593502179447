import {
  gql,
} from 'apollo-boost';

export default gql `
mutation ($data: JobInput!) {
  updateJob(data: $data) {
    id
    job_no
    job_status {
      id
      description
    }
    job_source {
      id
      description
    }
    invoicing_email
    quest_ref
    terms_received
    quote_date
    conflict_checked
    storage_reference
    purchase_order_no
    property_type {
      id
      description
    }
    property_condition {
      id
      description
    }
    property_age {
      id
      description
    }
    number_of_beds
    purchase_price
    specific_requirements
    assigned_user {
      id
      forename
      surname
    }
    job_date
    created_at
    job_addresses {
      job_address_type {
        id
        description
      }
      address {
        id
        abode_name_no
        street
        city
        county
        postcode
      }
    }
    job_breakdown {
      description
      job_type {
        id
        description
      }
      net
      gross
    }
    clients {
      id
      title {
        id
        description
      }
      first_name
      last_name
      contact_methods {
        id
        contact_medium {
          id
          description
        }
        val
      }
    }
    notes {
      id
      note
      created_at
      user {
        id
        forename
        surname
      }
    }
    use_single_invoice_value
    single_invoice_value_net
    single_invoice_value_gross
  }
}
`;
