import {gql} from 'apollo-boost';

export default gql`
mutation ($uploadId: Float!, $jobId: Float!, $emailTemplateId: String!, $emails: [String]!){
  sendJobUpload(uploadId: $uploadId, jobId: $jobId, emailTemplateId: $emailTemplateId, emails: $emails){
    upload_id
    email{
      id
      date_sent
      email
      status
    }
  }
}
`;
