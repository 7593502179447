import {gql} from 'apollo-boost';

export default gql `
mutation ($jobId: Float!, $upload: UploadInput!) {
  createJobUpload(jobId: $jobId, upload: $upload) {
    upload_id
    upload {
      id
      name
      file_path
      created_at
      active
    }
    job_id
  }
}
`;
