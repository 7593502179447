import {gql} from 'apollo-boost'

export default gql`
{
  jobTypes{
    id
    description
    active
  }
}
`