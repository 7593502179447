import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {
  Alert,
} from 'reactstrap';

import Loading from '../global/Loading';
import Invoice from './Invoice';

import query from '../../queries/paymentInvoice';
import setInvoicePaidMutation from '../../mutations/setInvoicePaid';

const queryParams = new URLSearchParams(window.location.search);

const Payment = (props) => {
  const {companyId, invoiceId} = useParams();
  const {data, loading, error} = useQuery(
      query,
      {
        variables: {
          id: invoiceId,
          companyId,
        },
        fetchPolicy: 'network-only',
      },
  );

  const [setInvoicePaid, piRes] = useMutation(setInvoicePaidMutation);

  const payInvoice = async () => {
    await setInvoicePaid({variables: {invoiceId, paymentIntentId: queryParams.get('payment_intent')}});
  };

  React.useEffect(() => {
    payInvoice();
  }, []);

  if (loading || piRes.loading) return <Loading/>;

  if (piRes.error || error) {
    return <Alert color="danger m-4">Error: Something went wrong. Please contact us.</Alert>;
  }

  return (
    <div className="d-flex justify-content-center pt-3">

      <div className="d-flex flex-column border p-5" style={{width: '100%', maxWidth: '968px'}}>

        <Invoice
          headerImageUrl={data.companySetting.val}
          invoiceNo={piRes.data.setInvoicePaid.invoice_no}
          jobTypesSummary={piRes.data.setInvoicePaid.job.job_types_summary}
          totalValueNet={piRes.data.setInvoicePaid.total_value_net}
          totalVat={piRes.data.setInvoicePaid.total_vat}
          totalValueGross={piRes.data.setInvoicePaid.total_value_gross}
          datePaid={piRes.data.setInvoicePaid.date_paid}
        />

      </div>

    </div>

  );
};

export default Payment;
