import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import EmailListItem from './EmailListItem';

const EmailList = ({emails}) => {
  return (
    <div>
      {
        emails
            .sort((a, b) => moment(b.date_sent).unix() - moment(a.date_sent).unix())
            .map((email, index) => {
              return <EmailListItem key={index} email={email} />;
            })
      }
    </div>
  );
};

EmailList.propTypes = {
  emails: PropTypes.array,
};

export default EmailList;
